import styled, { css } from "styled-components";
import { AnimationContainer } from "../AnimationContainer";
import { LogosContainer } from "../Logos/";

const size = 800;

export const Container = styled.div`
  position: relative;
  background-size: cover;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  &::before,
  &::after {
    ${({ glowyBits }) =>
      glowyBits &&
      css`
        content: "";
        position: absolute;
        width: ${size}px;
        height: ${size}px;
        ${({ theme }) => theme.radialGlow(theme.colors.primary)};
        border-radius: 50%;
        z-index: -1;
        opacity: 0.9;
      `}
  }
  &::before {
    top: 100vh;
    left: -${size / 2}px;
  }
  &::after {
    bottom: 400px;
    left: -${size / 2}px;
  }
`;

export const Copy = styled(AnimationContainer)`
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h4 {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: left;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: left;
    color: #8193ae;
  }
`;

export const Content = styled(AnimationContainer)`
  grid-area: b;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 64px 32px 64px 0;
  gap: 32px;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    padding: 32px;
  }
  @media (max-width: ${({ theme }) => theme.phone}) {
    padding: 24px;
    align-items: stretch;
  }
  ${LogosContainer} {
    align-items: flex-start;
  }
`;
