import React from "react";
import styled from "styled-components";
import { ICONS } from "#src/common/icons";
import { IconWrapper } from "./Icon.styles";

const Icon = ({ icon, size, color, ...other }) => {
  return (
    <IconWrapper size={size} color={color} {...other}>
      {ICONS[icon]}
    </IconWrapper>
  );
};

export const IconBox = styled(Icon)`
  ${({ theme }) => theme.gradientStroke()};
  ${({ theme }) => theme.flex()};
  width: 80px;
  height: 80px;
  background: radial-gradient(476.77% 427.37% at 224.88% 363.32%, rgba(103, 116, 244, 0.60) 0%, rgba(103, 116, 244, 0.00) 100%), rgba(26, 36, 48, 0.35);
  backdrop-filter: blur(20px);
  > * {
    position: relative;
    z-index: 1;
  }
  svg {
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export default Icon;
