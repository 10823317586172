import styled, { css } from "styled-components";
import { AnimationContainer } from "../AnimationContainer";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Left = styled(AnimationContainer)`
  position: relative;
  grid-area: ${({ reverse }) => (reverse ? "r" : "l")};
  display: flex;
  flex-direction: column;
  gap: clamp(24px, 3.3vh, 36px);
  justify-content: center;
  align-items: flex-start;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    align-items: stretch;
  }
  p {
    color: ${({ theme }) => theme.colors.secondary};
  }
  label {
    align-self: flex-start;
  }
`;
export const Right = styled(Left)`
  grid-area: ${({ reverse }) => (reverse ? "l" : "r")};
  align-items: center;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    align-items: flex-start;
  }
  img {
    width: 100%;
    @media (max-width: ${({ theme }) => theme.tablet}) {
      max-width: 460px;
    }
    object-fit: contain;
    ${({ reverse }) =>
      reverse
        ? css`
            right: 0;
          `
        : css`
            left: 0;
          `}
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => (!!columns ? columns : "1fr 1fr")};
  grid-template-areas: "l r";
  max-width: ${({ theme }) => theme.maxWidth};
  width: 100%;
  gap: ${({ gap }) => (!!gap ? gap : "72px")};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    gap: 32px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  @media (min-width: ${({ theme }) => theme.desktop}) {
    ${({ imgHeight }) =>
      imgHeight &&
      css`
        ${Right} {
          height: ${imgHeight};
          img {
            position: absolute;
            width: auto;
            height: ${imgHeight};
          }
        }
      `}
  }
`;
