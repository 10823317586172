import React from "react";
import {
  SeparatorSection,
  ImageBackground,
  ThreeColumnLayout,
  FeatureList,
} from "#src/components";
import { useMedia } from "#src/hooks";

const NavSection = ({sections, image, ...other}) => {
  const { isMobile } = useMedia();
  return (
    <SeparatorSection>
      <ThreeColumnLayout
        areas={isMobile ? '"c1" "c3" "c2" ' : undefined}
        columns={[
          <FeatureList
            alignRight
            alt
            features={sections.slice(0, Math.round(sections.length / 2)).map((section) => {
              return { title: section.label, url: `#${section.id}` };
            })}
          />,
          <ImageBackground {...image} />,
          <FeatureList
            alt
            features={sections.slice(Math.round(sections.length / 2), sections.length).map((section) => {
              return { title: section.label, url: `#${section.id}` };
            })}
          />,
        ]}
        {...other}
      />
    </SeparatorSection>
  );
};

export default NavSection;
