import React from "react";
import { StyledSvg } from "./Hero.styles";

export default function Wireframe({ other }) {
  return (
    <StyledSvg
      {...other}
      width="1010"
      height="631"
      viewBox="0 0 1010 631"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="url(#linear)"
    >
      <defs>
        <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="rgba(255 255 255 / 0)" />
          <stop offset="30%" stopColor="rgb(255 255 255 / 0.1)" />
          <stop offset="60%" stopColor="rgb(255 255 255 / 0.1)" />
          <stop offset="100%" stopColor="rgb(255 255 255 / 0)" />
        </linearGradient>
      </defs>
      <path d="M140.484 0V630.581" />
      <rect x="162.215" y="59.3989" width="147.627" height="105.162" rx="7.5" />
      <rect x="320.842" y="59.3989" width="147.627" height="105.162" rx="7.5" />
      <rect x="479.469" y="59.3989" width="147.627" height="105.162" rx="7.5" />
      <rect x="638.096" y="59.3989" width="147.627" height="105.162" rx="7.5" />
      <rect x="796.723" y="59.3989" width="147.627" height="105.162" rx="7.5" />
      <rect x="955.35" y="59.3989" width="29.5312" height="22.12" rx="5" />
      <rect x="955.35" y="88.019" width="29.5312" height="22.12" rx="5" />
      <rect x="162.934" y="213.64" width="823.025" height="37.3639" rx="6.5" />
      <rect x="162.934" y="257.003" width="823.025" height="37.3639" rx="6.5" />
      <rect x="162.934" y="300.367" width="823.025" height="37.3639" rx="6.5" />
      <rect x="162.934" y="343.731" width="823.025" height="37.3639" rx="6.5" />
      <rect x="162.934" y="387.095" width="823.025" height="37.3639" rx="6.5" />
      <rect x="162.934" y="430.459" width="823.025" height="37.3639" rx="6.5" />
      <rect x="162.934" y="473.823" width="823.025" height="37.3639" rx="6.5" />
      <rect x="162.934" y="517.187" width="823.025" height="37.3639" rx="6.5" />
    </StyledSvg>
  );
}
