import React from "react";
import { SeparatorSection, AnimatedText, InfoBoxGrid, AnimationContainer, Label } from "#src/components";

const FeaturesGridSection = ({ id, title, features, label }) => {
  return (
    <SeparatorSection id={`${id}`}>
      {label && (
        <AnimationContainer>
          <Label text={label} />
        </AnimationContainer>
      )}
      <h3 className="center mb-10">
        <AnimatedText text={title} />
      </h3>
      <InfoBoxGrid boxes={features} />
    </SeparatorSection>
  );
};

export default FeaturesGridSection;
