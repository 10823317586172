import React from "react";
import {
  HeroSection,
  LeftRightSection,
  FeaturesGridSection,
  NavSection,
  PreFooterSection,
  ColumnsSection,
  GridSection,
  LogoSection,
  QuoteSection,
  PartnerSection,
} from "#src/sections";

const SectionSwitch = ({ type, ...other }) => {
  switch (type) {
    case "hero":
      return <HeroSection {...other} />;
    case "left-right":
      return <LeftRightSection {...other} />;
    case "features grid":
      return <FeaturesGridSection {...other} />;
    case "nav":
      return <NavSection {...other} />;
    case "prefooter":
      return <PreFooterSection {...other} />;
    case "columns":
      return <ColumnsSection {...other} />;
    case "grid":
      return <GridSection {...other} />;
    case "logos":
      return <LogoSection {...other} />;
    case "quote":
      return <QuoteSection {...other} />;
    case "partner":
      return <PartnerSection {...other} />;
    default:
      return null;
  }
};

export default SectionSwitch;
