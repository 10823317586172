import React from "react";
import { BlurryBackgroundWrapper, BlobsContainer, Blob } from "./Aurora.styles";
import { randomValue, shuffle } from "#src/Helpers";
const Aurora = ({
  children,
  length = 5,
  size = 700,
  sizeRandomnessAmplitude = 200,
  sizeUniformityAmplitude = 200,
  x = [-100, 100],
  y = [-200, 200],
  opacity = [0.5, 1],
  ...other
}) => {
  const imgs = [
    "/images/blurs/blur_bokeh.webp",
    "/images/blurs/blur_mauve.webp",
    "/images/blurs/blur_pink.webp",
    "/images/blurs/blur_purple.webp",
    "/images/blurs/blur_teal.webp",
    "/images/blurs/blur_blue.webp",
  ];
  const calcSize = () => size + randomValue(-(sizeRandomnessAmplitude / 2), sizeRandomnessAmplitude / 2);
  const imgsShuffled = shuffle(imgs);
  const blobs = Array.from({ length: length }, (_, index) => ({
    backgroundImage: `url(${imgsShuffled[index % imgs.length]})`,
    width: `${randomValue(calcSize() - sizeUniformityAmplitude, calcSize() + sizeUniformityAmplitude)}px`,
    height: `${randomValue(calcSize() - sizeUniformityAmplitude, calcSize() + sizeUniformityAmplitude)}px`,
    opacity: randomValue(opacity[0], opacity[1]),
    rotate: `${randomValue(0, 90)}deg`,
    translate: `${randomValue(x[0], x[1])}px ${randomValue(y[0], y[1])}px`,
  }));
  return (
    <BlurryBackgroundWrapper {...other}>
      <BlobsContainer>
        {blobs.map((blob, i) => (
          <Blob key={i} style={blob} />
        ))}
      </BlobsContainer>
      {children}
    </BlurryBackgroundWrapper>
  );
};

export default Aurora;
