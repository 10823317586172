import styled from "styled-components";

export const GradientSeparator = styled.div`
  width: 210px;
  height: 1px;
  background: linear-gradient(270deg, rgba(93, 85, 240, 0.00) -0.18%, rgba(93, 85, 240, 0.87) 78.01%, rgba(93, 85, 240, 0.00) 102.73%);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 190px 1px;
`;
