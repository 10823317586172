import React from "react";
import {
  Container,
  LeftRightLayout,
  Section,
  SeparatorSection,
  CommonHero,
  PreFooter,
  GradientSeparator,
  AnimatedText,
  Logos,
  GridLayout,
  SlidesLayout,
  Feature,
  FAQ,
  ShareMeta,
} from "#src/components";
import { corporateData as data } from "#src/data";
import { useMedia } from "#src/hooks";

const Corporate = () => {
  const { isTablet } = useMedia();
  const { hero, sections } = data;
  return (
    <Container>
      <ShareMeta title={hero.title} description={hero.text} type="website" />
      <CommonHero {...hero} imageProps={{ screen: 1 }} />
      <Section>
        <h3 className="center">
          <AnimatedText text={sections[0].title} />
        </h3>
        <GradientSeparator />
        <SlidesLayout slides={sections[0].slides} />
      </Section>
      <SeparatorSection className={isTablet ? "" : "pt-0"}>
        <h3 className={isTablet ? "center" : "center text-4xl mb-20"}>
          <AnimatedText text={sections[0].subtitle} />
        </h3>
        <GridLayout className="overflow-hidden pb-10" columns={5} gap={isTablet ? "36px" : "68px 32px"}>
          {sections[0].features.map((feature, i) => (
            <Feature sm delay={i * 0.15} key={i} {...feature} />
          ))}
        </GridLayout>
      </SeparatorSection>
      <SeparatorSection className="pb-0" id="structure">
        <LeftRightLayout
          imgHeight="600px"
          leftClassName="pb-20"
          columns="0.8fr 1.2fr"
          left={
            <>
              <h3 className="desktop:pr-10">
                <AnimatedText text={sections[1].title} />
              </h3>
              <h4>
                <AnimatedText text={sections[1].subtitle} />
              </h4>
              <p>
                <AnimatedText block text={sections[1].text} />
              </p>
            </>
          }
          right={<img src={sections[1].image} alt={sections[1].title} />}
        />
      </SeparatorSection>
      <SeparatorSection>
        <LeftRightLayout
          reverse
          left={
            <>
              <h3 className="desktop:pr-10">
                <AnimatedText text={sections[2].title} />
              </h3>
              <h4>
                <AnimatedText text={sections[2].subtitle} />
              </h4>
              <p>
                <AnimatedText block text={sections[2].text} />
              </p>
              <Logos delay={0.8} {...sections[2].logos} />
            </>
          }
          right={<img src={sections[2].image} alt={sections[2].title} />}
        />
      </SeparatorSection>
      <SeparatorSection>
        <LeftRightLayout
          left={
            <>
              <h3 className="desktop:pr-10">
                <AnimatedText text={sections[3].title} />
              </h3>
              <h4>
                <AnimatedText text={sections[3].subtitle} />
              </h4>
              <p>
                <AnimatedText block text={sections[3].text} />
              </p>
            </>
          }
          right={<img src={sections[3].image} alt={sections[3].title} />}
        />
      </SeparatorSection>
      <PreFooter />
      <SeparatorSection>
        <h2>
          <AnimatedText text={sections[4].title} />
        </h2>
        <GradientSeparator />
        <FAQ data={sections[4].data} />
      </SeparatorSection>
    </Container>
  );
};

export default Corporate;
