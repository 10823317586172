import React, { useEffect } from "react";
import { useAnimation, motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";

const bars = 30;
const strokeLength = 100;

const ScreenContainer = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  display: block;
  width: 100vw;
  height: 100vh;
  z-index: 998;
`;

const BarsContainer = styled.div`
  position: absolute;
  z-index: 0;
  width: 100vmax;
  height: 100vmax;
  display: grid;
  grid-template-columns: repeat(${bars}, 1fr);
  transform: rotate(45deg) scale(1.3);
`;

const Bar = styled(motion.div)`
  will-change: transform;
  background-color: #00141f;
`;

const Screen = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    z-index: 1;
    path {
      stroke-dasharray: ${strokeLength};
      stroke-dashoffset: ${strokeLength};
      animation: 5s infinite dashAnim;
    }
  }
  @keyframes dashAnim {
    0% {
      stroke-dashoffset: -${strokeLength};
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: ${strokeLength};
    }
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: none;
    margin-top: 1.5rem;
    opacity: 0.7;
  }
`;

function LoadingScreen({ loading = true, className, message }) {
  const ctrls = useAnimation();
  const barAnim = {
    initial: { scaleX: "101%" },
    animate: { scaleX: "101%" },
    exit: { scaleX: 0 },
    transition: { duration: 0.2, ease: [0.2, 0.65, 0.3, 0.9] },
  };
  const svgAnim = {
    initial: { opacity: 1 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.2, ease: [0.2, 0.65, 0.3, 0.9] },
  };

  useEffect(() => {
    if (loading) ctrls.start("visible");
    if (!loading) ctrls.start("hidden");
  }, [ctrls, loading]);

  return (
    <AnimatePresence>
      {loading ? (
        <ScreenContainer id="loading" className={className}>
          <Screen>
            <BarsContainer>
              {[...Array(bars)].map((x, i) => (
                <Bar {...barAnim} key={i} />
              ))}
            </BarsContainer>
            <motion.svg
              {...svgAnim}
              width="80"
              height="156"
              viewBox="0 0 22 41"
              preserveAspectRatio="xMinYMin meet"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="mark" stroke="#5D55F0" fill="transparent" strokeWidth="0.4">
                <path
                  id="Combined-Shape"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.3886 9.81706L10.4737 0.119141L0.53125 9.81145L10.4737 19.5307L20.3886 9.81706ZM5.28125 9.84903L10.4421 4.78027L15.5965 9.82548L10.4625 14.8753L5.28125 9.84903Z"
                  fill="url(#paint0_linear_647_55295)"
                />
                <path
                  id="Fill-4"
                  d="M10.4637 22.6163L0.53125 12.9268L0.54246 17.5861L10.4775 27.3019L20.3878 17.5855V12.9268L10.4637 22.6163Z"
                  fill="url(#paint1_linear_647_55295)"
                />
                <path
                  id="Fill-7"
                  d="M0.5 25.3563L8.75503 33.382V39.0004H12.1136V33.445L20.3839 25.3566V20.6974L10.4623 30.411L0.5 20.667V25.3563Z"
                  fill="url(#paint2_linear_647_55295)"
                />
              </g>
            </motion.svg>
            {message && <motion.h4 {...svgAnim}>{message}</motion.h4>}
          </Screen>
        </ScreenContainer>
      ) : null}
      ;
    </AnimatePresence>
  );
}
export default React.memo(LoadingScreen);
