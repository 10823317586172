export const ROUTES = {
  SECRETPLAYGROUND: { path: "/secretplayground", title: "Secret playground" },
  HOME: { path: "/", title: "Home" },
  PRODUCT: {
    path: "/product",
    title: "Product",
  },
  SECURITY: { path: "/security", title: "Security" },
  ABOUT: { path: "/about", title: "About" },
  NEWS: { path: "/news", title: "News" },
  POST: { path: "/news/:slug" },
  SERVICES: { path: "/services-agreement", title: "Services agreement" },
  PRIVACY: { path: "/privacy-policy", title: "Privacy policy" },
  DEMO: { path: "/demo", title: "Book a demo" },
  THANKYOU: { path: "/demo/confirmation", title: "Thanks for booking a call!" },
  CRF: { path: "/crf", title: "Corporate Registers Forum" },
  PARTNERSHIP: { path: "/partnership", title: "Partnership" },
  KYCAML: {
    path: "/product/kyc-aml",
    title: "KYC/AML",
  },
  TASKMANAGER: {
    path: "/product/task-manager",
    title: "Task manager",
  },
  QUESTIONNAIRES: {
    path: "/product/questionnaires",
    title: "Questionnaires",

  },
  ENTITYMANAGEMENT: {
    path: "/product/entity-management",
    title: "Entity management",

  },
  CORPORATE: {
    path: "/product/corporate",
    title: "Corporate",
  },
  REPORTING: {
    path: "/product/reporting",
    title: "Reporting",
  },
  CORPORATESTRUCTUREVERIFICATION: {
    path: "/product/corporate-structure-verification",
    title: "Corporate Structure Verification",
  },
  RISKASSESSMENT: {
    path: "/product/risk-assessment",
    title: "Risk assessment",
  },
};

export const ENV = process.env.NODE_ENV

export const PUBLISHEDPOSTSURL = "https://hs-proxy.binderr.com/cms/v3/blogs/posts?state=PUBLISHED";
export const POSTSURL = "https://hs-proxy.binderr.com/cms/v3/blogs/posts";
export const DEVPOSTSURL = "http://hs-proxy.binderr.dev/cms/v3/blogs/posts/";
export const AUTHORSURL = "https://hs-proxy.binderr.com/cms/v3/blogs/authors";
export const DEVAUTHORSURL = "http://hs-proxy.binderr.com/cms/v3/blogs/authors";
export const POSTURL = (slug) => `https://hs-proxy.binderr.com/cms/v3/blogs/posts?slug=binderr-blog/${slug}`;
export const AUTHORURL = (id) => `https://hs-proxy.binderr.com/cms/v3/blogs/authors?id=${id}`;
