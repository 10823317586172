import React from "react";
import { FeatureWrapper, FeatureListWrapper, Content, StyledNavLink } from "./Feature.styles";
import { AnimationContainer, IconBox, Icon } from "#src/components";

const Feature = ({ title, text, icon, sm, alt, url, alignRight, ...other }) => {
  return (
    <FeatureWrapper alt={alt ? 1 : 0} sm={sm ? 1 : 0} {...other}>
      {!alt && !sm && (
        <AnimationContainer>
          <IconBox icon={icon} />
        </AnimationContainer>
      )}
      <Content>
        <h4>
          {!!alt || (!!sm && <Icon size="24px" icon={icon} />)}
          {!sm && !url && title}
        </h4>
        {url && <StyledNavLink to={url}>{title}</StyledNavLink>}
        {!!title && <p>{sm ? title : text}</p>}
      </Content>
    </FeatureWrapper>
  );
};

export default Feature;

export const FeatureList = ({ features, className, alt, sm, other, alignRight }) => {
  return (
    <FeatureListWrapper className={className} alignRight={alignRight}>
      {features.map((feature, i) => (
        <Feature
          key={i}
          title={feature.title}
          text={feature.text}
          icon={feature.icon}
          url={feature.url}
          delay={i * 0.15}
          alt={alt}
          sm={sm}
          {...other}
        />
      ))}
    </FeatureListWrapper>
  );
};
