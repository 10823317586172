import styled from "styled-components";
import { AnimationContainer } from "../AnimationContainer";
import { rgba } from "#src/Helpers";

export const CardDrawer = styled.div`
  cursor: pointer;
  * {
    cursor: pointer;
  }
  position: absolute;
  left: 14px;
  bottom: -32px;
  transition: height 0.6s cubic-bezier(0.73, 0, 0.01, 1);
  padding: 18px 23px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 220px;
  height: auto;
  border-radius: 17.904px;
  border: 1px solid ${({ theme }) => rgba(theme.colors.primary, 0.2)};
  background: linear-gradient(266deg, rgba(24, 37, 58, 0.9) -11.19%, rgba(30, 38, 55, 0.9) 57.22%);
  z-index: 1;
  backdrop-filter: blur(32px);
`;

export const Details = styled.div`
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => rgba(theme.colors.secondary, 0.8)};
  text-transform: none;
  height: 0;
  svg {
    rotate: -90deg;
    translate: 8px 3px;
  }
`;

export const Card = styled(AnimationContainer)`
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  aspect-ratio: 382 / 510;
  box-shadow: 10px 10px 60px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  backface-visibility: hidden;
  /* perspective: 1000; */
  transform: translate3d(0, 0, 0);
  position: relative;
  ${({ theme, img }) => theme.bgImg(img)};
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 18px;
    cursor: pointer;
    z-index: 0;
  }
  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.title};
  }
  label {
    font-style: normal;
    font-size: 18px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.secondary};
    text-transform: none;
    font-weight: 500;
  }
  p {
    font-style: normal;
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.secondary};
  }
  a {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 10px;
    height: 56px;
    text-transform: none;
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: filter 0.2s ${({ theme }) => theme.ease};
    &:hover {
      filter: brightness(2);
    }
    &:before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      background-image: url("/images/icons/ln.svg");
      margin-right: 8px;
    }
  }
  ${CardDrawer} {
    label {
      font-size: 14px;
    }
    ${Details} {
      transition: opacity 0.6s cubic-bezier(0.73, 0, 0.22, 1.02), height 0.6s cubic-bezier(0.73, 0, 0.22, 1.02);
      opacity: 0;
    }
  }
  &:hover {
    ${CardDrawer} {
      ${Details} {
        opacity: 1;
        height: 24px;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    ${CardDrawer}, &:hover ${CardDrawer} {
      transform: translate3d(0, 0, 0);
    }
  }
`;

export const CardModal = styled(Card)`
  flex-direction: row;
  gap: 48px;
  background: none;
  box-shadow: none;
  height: 100%;
  flex: 1;
  aspect-ratio: auto;
  img {
    height: 60vh;
    width: auto;
    cursor: default;
  }
  h2:after {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: visible;
    padding: 32px 64px 32px 32px;
    img {
      object-fit: cover;
      object-position: top center;
      width: 40%;
      height: auto;
      aspect-ratio: 1;
      cursor: default;
    }
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    overflow: auto;
    padding: 72px 32px;
    gap: 32px;
    width: 100vw;
    img {
      width: 40%;
    }
  }
`;
export const CardModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 33vw;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    width: 100%;
  }
`;
