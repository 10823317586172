import { StyledNavbar, Container, Intersection } from "./Navbar.styles";
import React, { useEffect, useState } from "react";
import { useMedia } from "#src/hooks";
import { MenuDesktop } from "./MenuDesktop";
import { MenuMobile } from "./MenuMobile";

function Navbar({ items }) {
  const { isTablet } = useMedia();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => setIsScrolled(window.scrollY > 200);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <StyledNavbar className="navigation" isScrolled={isScrolled}>
      <Container>{isTablet ? <MenuMobile items={items} /> : <MenuDesktop items={items} />}</Container>
      {/* <Intersection /> */}
    </StyledNavbar>
  );
}

export default React.memo(Navbar);
