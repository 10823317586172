// useCenterDistance.js
import { useEffect, useState, useRef } from 'react';

export const useCenterDistance = (callback) => {
  const ref = useRef(null);
  const [centerDistanceRatio, setCenterDistanceRatio] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const distanceRatio = getCenterDistanceRatio(ref.current);
      setCenterDistanceRatio(distanceRatio);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (typeof callback === 'function') {
      callback(centerDistanceRatio);
    }
  }, [centerDistanceRatio, callback]);

  const getCenterDistanceRatio = (el) => {
    if (!el) return 0;

    const { top, bottom, height } = el.getBoundingClientRect();
    const centerY = top + height / 2;
    const screenCenterY = window.innerHeight / 2;

    // Calculate the distance between the centers and normalize it to the height of the element
    const distanceRatio = Math.abs(screenCenterY - centerY) / height;

    return Math.min(distanceRatio, 1); // Ensure the ratio is between 0 and 1
  };

  return { ref, centerDistanceRatio };
};
