import React, { useRef, useEffect, useContext } from "react";
import { ModalContainer, ModalWindow } from "./Modal.styles";
import { ValueContext, DispatchContext } from "../../context/Context";
import { AnimatePresence } from "framer-motion";
import { useScrollBlock } from "../../hooks";

const Modal = () => {
  const { modalOpen, modalChildren, containerStyle, modalStyle } = useContext(ValueContext);
  const dispatch = useContext(DispatchContext);
  const ref = useRef(null);
  const [blockScroll, allowScroll] = useScrollBlock();
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch({ type: "setModalOpen", modalOpen: false });
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, dispatch]);
  useEffect(() => {
    modalOpen ? blockScroll() : allowScroll();
  }, [modalOpen, blockScroll, allowScroll]);
  return (
    <AnimatePresence>
      {modalOpen && (
        <ModalContainer
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          style={containerStyle}
        >
          <ModalWindow
            ref={ref}
            initial={{ y: 30 }}
            animate={{ y: 0 }}
            exit={{ y: 30 }}
            transition={{ duration: 0.2 }}
            style={modalStyle}
          >
            {modalChildren}
          </ModalWindow>
        </ModalContainer>
      )}
    </AnimatePresence>
  );
};

export default React.memo(Modal);
