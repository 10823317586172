import React from "react";
import { SeparatorSection, AnimatedText, GridLayout, AnimationContainer, Label } from "#src/components";

const GridSection = ({ id, title, label, grid, options }) => {
  return (
    <SeparatorSection id={`${id}`}>
      {label && (
        <AnimationContainer>
          <Label text={label} />
        </AnimationContainer>
      )}
      <h3 className="center mb-10">
        <AnimatedText text={title} />
      </h3>
      <GridLayout {...options}>
        {grid.map((item, i) => (
          <AnimationContainer delay={options?.columns ? (i % options.columns) * 0.15 : (i % 3) * 0.15} key={i}>
            {item?.label && <Label text={label} />}
            {item?.image && <img src={item?.image} alt={item?.alt ? item.alt : ""} />}
          </AnimationContainer>
        ))}
      </GridLayout>
    </SeparatorSection>
  );
};

export default GridSection;
