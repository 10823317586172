import React from "react";
import { Wrapper, IconBackground, Icon } from "./BgIcon.styles";

const BgIcon = ({ icon, active, styles = [{}, {}, {}], ...other }) => {
  return (
    <Wrapper active={active} style={styles[0]} {...other}>
      <IconBackground style={styles[1]} />
      <Icon src={icon} style={styles[2]} alt="icon" width="19" height="19" />
    </Wrapper>
  );
};

export default BgIcon;
