import React, { forwardRef } from "react";
import { HeroSection, HeroLayout, ArrowNav } from "./Hero.styles";
import { Icon } from "#src/components";

const Hero = forwardRef(({ left, right, arrowText, ...other }, ref) => {
  const handleClick = () => {
    window.scrollTo({ top: window.innerHeight, behavior: "smooth" });
  };
  return (
    <HeroSection ref={ref}>
      <HeroLayout left={left} right={right} {...other} />
      {arrowText && (
        <ArrowNav onClick={handleClick}>
          <span>{arrowText}</span>
          <Icon icon="ARROW_BOTTOM" />
        </ArrowNav>
      )}
    </HeroSection>
  );
});

export default Hero;
