import styled from "styled-components";

export const ActionsContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 1rem;
  @media (max-width: ${({ theme }) => theme.phone}) {
    flex-direction: row;
    > * {
      flex: 1;
      button {
        width: 100%;
      }
    }
  }
`;
