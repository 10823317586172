import styled, { css } from "styled-components";
import { AnimationContainer } from "#src/components";

const imageHeight = "200px" 

export const InfoBoxWrapper = styled(AnimationContainer)`
  ${({ theme }) => theme.gradientStroke()};
  > * {
    position: relative;
    z-index: 1;
  }
  padding: 32px 32px 48px;
  min-height: 300px;
  background: radial-gradient(
      187.73% 176.07% at -98.85% 165.95%,
      rgba(103, 116, 244, 0.6) 0%,
      rgba(103, 116, 244, 0) 100%
    ),
    rgba(26, 36, 48, 0.35);
  backdrop-filter: blur(20px);
  @media (max-width: ${({ theme }) => theme.tablet}) {
    /* width: 343px; */
    height: auto;
    margin-right: 0px;
    background: none;
  }
  h4 {
    margin-bottom: 12px;
  }
  p {
    color: ${({ theme }) => theme.colors.secondary};
  }
  svg {
    margin-bottom: 12px;
    color: ${({ theme }) => theme.colors.primary};
    @media (max-width: ${({ theme }) => theme.tablet}) {
      margin-bottom: 7px;
    }
  }
  ${({ image }) =>
    image &&
    css`
      padding-top: calc(${imageHeight} + 32px);
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: ${imageHeight};
        background-image: url(${image});
        background-size: cover;
        border-bottom: 1px solid #29396f6b;
      }
    `}
`;
