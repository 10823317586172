import React from "react";
import { WrapperWrapper, Wrapper, StyledVideoPlayer } from "./PreFooter.styles";
import { AnimatedText, BookADemoButton, AnimationContainer } from "#src/components";
import { commonData } from "#src/data";
import { useMedia } from "#src/hooks";

const PreFooter = ({ children, img, data = commonData.prefooter, ...other }) => {
  const { isTablet } = useMedia();
  return (
    <WrapperWrapper>
      {!!data.video && !isTablet && (
        <StyledVideoPlayer url={data.video} options={{ playsInline: true, autoPlay: true, muted: true, loop: true }} />
      )}
      <Wrapper {...other} img={!!img ? img : data.bg} video={!!data?.video}>
        {!!children ? (
          children
        ) : (
          <>
            <h3>
              <AnimatedText text={data.title} />
            </h3>
            <p>
              <AnimatedText text={data.text} block delay={0.2} />
            </p>
            <AnimationContainer delay={0.3}>
              <BookADemoButton text={data.buttonText} />
            </AnimationContainer>
          </>
        )}
      </Wrapper>
    </WrapperWrapper>
  );
};

export default PreFooter;
