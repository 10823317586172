import React from "react";
import { Wrapper, Container, Content, StyledNavLink } from "./CenterBox.styles";
import AnimatedText from "../AnimatedText";

const CenterBox = ({ title, text, link, ...other }) => {
  return (
    <Wrapper {...other}>
      <Container>
        <Content>
          <h4>
            <AnimatedText text={title} />
          </h4>
          <StyledNavLink to={link}>
            <AnimatedText block text={text} />
          </StyledNavLink>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default CenterBox;
