import React, { useId } from "react";
import { SectionWrapper, SeparatorSectionWrapper, ContentWrapper } from "./Section.styles";
import { Aurora } from "#src/components";

export default function Section({ children, ...other }) {
  return (
    <SectionWrapper {...other}>
      <ContentWrapper>{children}</ContentWrapper>
    </SectionWrapper>
  );
}

export const SeparatorSection = ({ children, ...other }) => {
  return (
    <SeparatorSectionWrapper {...other}>
      <ContentWrapper>{children}</ContentWrapper>
      <Aurora
        style={{ position: "absolute", bottom: 0 }}
        length={2}
        size={700}
        opacity={[0.2, 0.3]}
        y={[0,100]}
        x={[-200, 200]}
      />
    </SeparatorSectionWrapper>
  );
};
