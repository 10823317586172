import styled from "styled-components";
import { ImageBackground, LeftRightLayout } from "#src/components";
export const Quote = styled.blockquote`
  cite {
    font-size: 1.7rem;
    line-height: 2.2rem;
    font-style: normal;
    z-index: 1;
    position: relative;
    display: block;
    margin-bottom: 1rem;
    opacity: 0.9;
    &:before {
      content: "“";
      translate: -2.3rem -0.5rem;
    }
    &:after {
      content: "”";
      right: 0;
      translate: 2.3rem -0.5rem;
    }
    &:before,
    &:after {
      top: 0;
      font-size: 6rem;
      line-height: 2.2rem;
      position: absolute;
      color: ${({ theme }) => theme.colors.secondary};
      opacity: 0.2;
    }
  }
  footer {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 1rem;
  }
`;
export const StyledImageBackground = styled(ImageBackground)`
  border-radius: 100%;
  width: 220px;
  height: 220px;
  min-height: 0;
  aspect-ratio: 1;
  margin-top: -0.5rem;
`;
export const StyledLeftRightLayout = styled(LeftRightLayout)``;
