import styled from "styled-components";

export const StyledFooter = styled.footer`
  ${({ theme }) => theme.flex()};
  padding: 96px 32px 128px 32px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(155deg, #00000073, #00000036);
  @media (max-width: ${({ theme }) => theme.mobile}) {
    justify-content: flex-start;
  padding: 96px 32px 128px 32px;
  }
`;
export const Content = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1.4fr;
  grid-template-areas: "brand product company touch";
  gap: 48px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "product company"
      "touch touch"
      "brand brand";
  }
  z-index: 1;
  > ul {
    ${({ theme }) => theme.flex("column", "flex-start", "flex-start")};
    padding: 0;
    gap: 14px;
    li {
      list-style: none;
      opacity: 0.6;
      &:first-child {
        font-size: 16px;
        opacity: 1;
        font-weight: 500;
        line-height: 24px;
        min-height: 60px;
        text-transform: uppercase;
        @media (max-width: ${({ theme }) => theme.mobile}) {
          text-transform: none;
          min-height: 48px;
        }
      }
      a {
        text-decoration: none;
        color: currentColor;
        font-weight: 400;
        transition: color 0.3s ${({ theme }) => theme.ease};
        &:hover {
          color: ${({ theme }) => theme.colors.primary};
        }
        &.active {
        }
      }
    }
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    opacity: 1;
  }
`;
