import React from "react";
import {
  Container,
  ShareMeta,
  SectionSwitch
} from "#src/components";
import { questionnairesData as data } from "#src/data";

const Questionnaires = () => {
  const { sections, meta } = data;
  return (
    <Container>
      <ShareMeta title={meta.title} description={meta.text} type="website" />
      {sections.map((section, i) => {return <SectionSwitch key={i} {...section} />})}
    </Container>
  );
};

export default Questionnaires;
