import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToAnchor() {
  const location = useLocation();
  const lastHash = useRef('');

  useEffect(() => {
    console.log(document.getElementById(lastHash.current))
    if (location.hash) {
      lastHash.current = location.hash.slice(1);
    }
    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        console.log(document.getElementById(lastHash.current));
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 200);
    }
  }, [location.hash, document.getElementById(lastHash.current)]);

  return null;
}

export default ScrollToAnchor;