import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { HeaderLink, FakeHeaderLink } from "../Navbar.styles";
import { mix } from "#src/Helpers";

export const Wrapper = styled.div`
  ${({ theme }) => theme.flex("row", "space-between", "flex-end")};
  width: 100%;
  z-index: 998;
`;

export const StyledHamburger = styled.button`
  border: 0;
  height: 40px;
  width: 40px;
  background-color: transparent;
  margin-left: auto;
  rect {
    transform-origin: center;
    transition: transform 0.3s ${({ theme }) => theme.ease};
    ${({ expanded }) =>
      expanded &&
      css`
        &:first-child {
          transform: rotate(45deg) translateX(4px);
        }
        &:last-child {
          transform: rotate(-45deg) translateX(4px);
        }
      `}
  }
`;

export const StyledMenu = styled(motion.div)`
  will-change: transform;
  background-image: url(/images/elements/grain.webp), url(/images/elements/bg.webp),
    linear-gradient(256deg, #012f47 -17.89%, #00141f 47.83%);
  background-repeat: repeat, no-repeat, no-repeat, no-repeat;
  background-size: auto auto, cover, cover, contain;
  background-position: center, center bottom -10px, center center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 997;
  &:before {
    content: "";
    width: 100%;
    height: 128px;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(1, 20, 32, 0.9) 0%,
      rgba(2, 23, 36, 0.7) 33%,
      rgba(2, 23, 36, 0.15) 70%,
      rgba(2, 23, 36, 0) 90%
    );
    z-index: 1;
  }
`;
export const MenuContainer = styled.div`
  padding: 112px 16px 16px 16px;
  height: 100vh;
  height: 100dvh;
  overflow-y: scroll;
  ${({ theme }) => theme.flex("column", "flex-start", "stretch")};
  ul {
    flex: 1;
    padding-bottom: 64px;
    li {
      list-style-type: none;
    }
  }
`;

export const mobileHeaderLinkStyles = css`
  margin-left: 0;
  font-size: 22px;
  z-index: 2;
  min-height: 64px;
  ${({ theme }) => theme.flex("row", "flex-start", "center")};
  gap: 8px;
  color: ${({ theme }) => theme.colors.secondary};
  &:hover {
    color: currentColor;
  }
  &.active {
    color: ${({ theme }) => mix(theme.colors.title, theme.colors.secondary, 0.3)};
  }
`;

export const MobileHeaderLink = styled(HeaderLink)`
  ${mobileHeaderLinkStyles}
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;
export const FakeMobileHeaderLink = styled(FakeHeaderLink)`
  ${mobileHeaderLinkStyles}
`;
