import styled from "styled-components"
import ReactPlayer from "react-player";

export const StyledVideo = styled(ReactPlayer)`
  aspect-ratio: 16 / 9;
  width: 100%;
  cursor: pointer;
  video {
    box-shadow: 20px 20px 100px #000b10, -20px -20px 60px #00070b75;
    border-radius: 4px;
  }
`;