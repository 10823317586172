import styled from "styled-components";

export const StyledSocialIcons = styled.ul`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  li {
    list-style: none;
    opacity: 1 !important;
  }

  a {
    background-color: #1a2430;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    margin-right: 10px;
    text-decoration: none;
    opacity: 0.6;
    transition: all 0.3s ${({ theme }) => theme.ease};
    &:hover {
      opacity: 1;
      .svg-social-icon path {
        fill: ${({ theme }) => theme.colors.white} !important;
        /* transition: all 0.3s ${({ theme }) => theme.ease}; */
      }
    }
  }
`;
