import styled from "styled-components";
export const StyledLabel = styled.label`
  padding: 4px 12px;
  font-size: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  line-height: 28px;
  border-radius: 12px;
  position: relative;
  display: inline-block;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 400;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 1px;
    z-index: -1;
    border: double 1px transparent;
    border-radius: 12px;

    background-image: linear-gradient(#1A2430, #1A2430),
      linear-gradient(
        120deg,
        rgba(103, 116, 244, 0.3) 10.63%,
        rgba(87, 100, 200, 0.22) 37.83%,
        rgba(39, 54, 71, 0) 117.22%
      );
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-repeat: no-repeat no-repeat;
  }
`;
