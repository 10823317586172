import styled from "styled-components";
import { Label } from "#src/components";
import { AnimationContainer, GridLayout, Logos } from "#src/components";

export const StyledLabel = styled(Label)`
  font-size: 14px;
`;

export const Feature = styled(AnimationContainer)`
  @media (min-width: ${({ theme }) => theme.mobile}) {
    padding: 16px;
    flex-direction: column;
  }
  display: flex;
  gap: 24px;
`;
export const FeatureText = styled.div`
  & > span {
    color: ${({ theme }) => theme.colors.secondary};
    &:first-child {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.title};
    }
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    padding-right: 16px;
  }
`;

export const StyledGridLayout = styled(GridLayout)`
  @media (min-width: ${({ theme }) => theme.tablet}) {
    background-image: url("/images/illustrations/line_bg.png");
  }
  background-position: center bottom;
  padding: 32px 0 48px 0;
`;
export const StyledLogos = styled(Logos)`
  gap: 32px;
  justify-content: center;
  text-align: center;
`;
