// useIntersectionScroll.js
import { useEffect, useState, useRef } from 'react';

export const useIntersectionScroll = (options = {}) => {
  const ref = useRef(null);
  const [intersectionRatio, setIntersectionRatio] = useState(0);
  const prevRatioRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const intersectionRatio = getIntersectionRatio(ref.current, options);
      if (intersectionRatio !== prevRatioRef.current) {
        setIntersectionRatio(intersectionRatio);
        prevRatioRef.current = intersectionRatio;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getIntersectionRatio = (el, opts) => {
    const defaultOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };
    const mergedOptions = { ...defaultOptions, ...opts };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIntersectionRatio(entry.intersectionRatio);
        }
      });
    }, mergedOptions);

    observer.observe(el);

    return null; // If the element is not intersecting
  };

  return { ref, intersectionRatio };
};