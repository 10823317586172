import React, { useState, useEffect } from "react";

import Theme from "./Theme";
import { Modal } from "./components";
import WebFont from "webfontloader";
import { ENV } from "./common/constants";
import { Helmet } from "react-helmet";
import { ContextProvider } from "./context";
import AppRouter from "./AppRouter";

function App() {
  const [loading, setLoading] = useState(true);
  const [fontsLoaded, setFontsLoaded] = useState(false);
  // const [imgsLoaded, setImgsLoaded] = useState(false);
  useEffect(() => {
    ENV !== "production" && console.log("env:", ENV);
    // const imgs = [aboutData.hero.bg, securityData.hero.bg];
    // cacheImages(imgs);
    WebFont.load({
      active: function () {
        // console.log("fonts loaded");
        setFontsLoaded(true);
      },
      custom: {
        families: ["Neue-Montreal"],
        urls: ["/fonts/fonts.css"],
      },
    });
  }, []);
  useEffect(() => {
    if (fontsLoaded) setLoading(false);
  }, [fontsLoaded]);
  return (
    <ContextProvider>
      <Helmet>
        <link rel="stylesheet" href="/index.css" />
      </Helmet>
      <Theme>
        <AppRouter loading={loading} />
        <Modal />
      </Theme>
    </ContextProvider>
  );
}

export default React.memo(App);
