import React from "react";
import { SeparatorSection, ThreeColumnLayout, AnimatedText, AnimationContainer, Label } from "#src/components";
import { Column } from "./ColumnsSection.styles";

const ColumnsSection = ({ id, title, text, label, columns }) => {
  return (
    <SeparatorSection id={`${id}`}>
      {label && (
        <AnimationContainer>
          <Label text={label} />
        </AnimationContainer>
      )}
      <h3 className="center mb-10">
        <AnimatedText text={title} />
      </h3>
      {text && <p>{text}</p>}
      <ThreeColumnLayout
        gap="6rem"
        columnWidths="1fr 1fr 1fr"
        columns={columns.map((column, i) => {
          return (
            <Column key={i}>
              <h4>
                <AnimatedText text={column.title} />
              </h4>
              <p>{column.text}</p>
            </Column>
          );
        })}
      />
    </SeparatorSection>
  );
};

export default ColumnsSection;
