import React, { createContext, useReducer } from "react";

const initialState = {
  modalOpen: false,
  modalChildren: null,
  containerStyle: null,
  modalStyle: null,
  newsData: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "setNewsData":
      return { ...state, newsData: action.newsData };
    case "setModalOpen":
      return { ...state, modalOpen: action.modalOpen };
    case "setModalChildren":
      return { ...state, modalChildren: action.modalChildren };
    case "setContainerStyle":
      return { ...state, containerStyle: action.containerStyle };
    case "setModalStyle":
      return { ...state, modalStyle: action.modalStyle };
    default:
      throw new Error("unexpected action type");
  }
};

export const ValueContext = createContext({});
export const DispatchContext = createContext({});

export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <DispatchContext.Provider value={dispatch}>
      <ValueContext.Provider value={state}>{children}</ValueContext.Provider>
    </DispatchContext.Provider>
  );
};
