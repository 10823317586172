import styled from "styled-components"

export const Wrapper = styled.div`
    flex: 1;
    padding: 64px 0;
    ${({ theme }) => theme.flex("column", "flex-start", "stretch")};
    gap: 42px;
    h3 {
        text-align: center;
    }
`