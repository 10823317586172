import React from "react";
import { ThemeProvider, createGlobalStyle, css } from "styled-components";
import { mix, rgba } from "#src/Helpers";

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);
window.addEventListener("resize", () => {
  vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

const PHONE = "480px";
const MOBILE = "768px";
const TABLET = "1180px";
const DESKTOP = "1280px";

const theme = {
  colors: {
    primary: "#5D55F0",
    secondary: "#8D9CAD",
    title: "#ffffff",
    border: "#89A3CB",
    dark: "#273647",
    bg: mix("#012F47", "#00141F"),
    bgLinear: "linear-gradient(256deg, #012F47 -17.89%, #00141F 47.83%)",
    glow1: "#3a73fd",
    glow2: "#82348c",
    article: "#0f161e",
  },
  phone: PHONE,
  mobile: MOBILE,
  tablet: TABLET,
  desktop: DESKTOP,
  maxWidth: "1180px",
  maxWidthSm: "860px",
  ease: "cubic-bezier(0.2, 0.65, 0.3, 0.9)",
  intersectionProps: {
    threshold: 0.4,
    triggerOnce: true,
  },
  max: {
    phone: (content) => css`
      @media (max-width: ${PHONE}) {
        ${content}
      }
    `,
    tablet: (content) => css`
      @media (max-width: ${TABLET}) {
        ${content}
      }
    `,
  },
  flex: (direction = "row", justify = "center", align = "center") => css`
    display: flex;
    flex-direction: ${direction};
    justify-content: ${justify};
    align-items: ${align};
  `,
  heroBg: (alpha = false) => css`
    background-image: url(/images/elements/grain.webp), url(/images/elements/mark.svg), url(/images/elements/bg.webp),
      ${alpha ? "url(/images/elements/dots.webp)" : "linear-gradient(256deg, #012f47 -17.89%, #00141f 47.83%)"};
    background-repeat: repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-size: auto auto, 55% auto, cover, ${alpha ? "1500px" : "cover"}, contain;
    background-position: center, right 22%, center bottom -10px, ${alpha ? "bottom left" : "center center"};
  `,
  pseudoFull: css`
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  `,
  gradientStroke: (
    radius = "24px",
    strokeWidth = "1px",
    gradient = "linear-gradient(120deg, rgba(103, 116, 244, 0.3) 10.63%, rgba(87, 100, 200, 0.22) 37.83%, rgba(39, 54, 71, 0) 117.22%)"
  ) => css`
    position: relative;
    border-radius: ${radius};
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 1px;
      z-index: 0;
      border: solid ${strokeWidth} transparent;
      border-radius: ${radius};
      background: ${gradient} border-box;
      mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      mask-composite: destination-out;
      mask-composite: exclude;
    }
  `,
  bgImg: (src, position = "center", size = "cover", repeat = "no-repeat") => css`
    background-size: ${size};
    background-position: ${position};
    background-repeat: ${repeat};
    background-image: url(${src});
  `,
  radialGlow: (color) => css`
    background: radial-gradient(
      circle,
      ${rgba(color, 0.5)} 0%,
      ${rgba(color, 0.45)} 10%,
      ${rgba(color, 0.1)} 45%,
      ${rgba(color, 0)} 65%
    );
  `,
  animationProps: (ctrls, delay = 0, x = 0, y = "40px", custom) => ({
    initial: "hidden",
    animate: ctrls,
    transition: {
      delay: delay,
    },
    variants: custom
      ? custom
      : {
          hidden: {
            opacity: 0,
            y: y,
            x: x,
          },
          visible: {
            opacity: 1,
            y: 0,
            x: 0,
            transition: {
              duration: 1,
              ease: [0.2, 0.65, 0.3, 0.9],
              delay: delay,
            },
          },
        },
  }),
};

const GlobalStyles = createGlobalStyle`
    
    img, video {
      max-width: none;
      height: auto;
    }

    *, *::before, *::after {
      font-family: 'Neue-Montreal', sans-serif;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    html {
      /* scroll-behavior: smooth; */
      background-color: ${({ theme }) => theme.colors.bg};
      background: ${({ theme }) => theme.colors.bgLinear};
      overflow-x: clip;
    }

    #root {
      color: ${({ theme }) => theme.colors.title};
      background-color: ${({ theme }) => theme.colors.bg};
      background: ${({ theme }) => theme.colors.bgLinear};
      --vh100: calc(var(--vh) * 100)
      overflow-x: clip;
    }
    h1 {
        font-size: clamp(3.5rem, 1.9054rem + 2.1622vh, 4.3rem);
        line-height: 70px;
        font-weight: 400;
        white-space: pre-line;
        @media (max-width: ${({ theme }) => theme.tablet}) {
          font-size: 36px;
          line-height: 36px;
        }
    }
    h2 {
        font-weight: 400;
        font-size: 56px;
        font-size: clamp(42px, 5vmin, 56px);
        line-height: 60px;
        white-space: pre-line;
        @media (max-width: ${({ theme }) => theme.tablet}) {
          font-size: 36px;
          line-height: 42px;
        }
    }
    h3 {
        font-weight: 400;
        font-size: 48px;
        line-height: 54px;
        @media (max-width: ${({ theme }) => theme.tablet}) {
          font-size: 32px;
          line-height: 38px;
        }
    }
    h1, h2, h3 {
      @media (max-width: ${({ theme }) => theme.phone}) {
        br {
          display: none;
        }
      }
    }

    h4 {
      font-weight: 400;
      font-size: 22px;
      line-height: 32px;
      white-space: pre-line;
    }

    p {
      color: ${({ theme }) => theme.colors.title};
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
    }

    a {
      text-decoration: none;
      color: currentColor;
    }

    .center {
      text-align: center;
      @media (max-width: ${({ theme }) => theme.tablet}) {
        text-align: left;
      }
    }

    body {
      /* overflow: ${({ theme }) => (theme.modalOpen ? "hidden" : "auto")}; */
      font-size: 18px;
      line-height: 23px;
      font-weight: 400;
      background-color: ${({ theme }) => theme.colors.bg};
      background: ${({ theme }) => theme.colors.bgLinear};
      overflow-x: clip;
      /* overflow-y: auto; */
      /* height: 100vh; */
      /* scroll-snap-type: y proximity; */
      @media (max-width: ${({ theme }) => theme.tablet}) {
        font-size: 18px;
        line-height: 23px;
      }
    }
`;

const Theme = ({ children, modalOpen }) => (
  <ThemeProvider id="root" theme={{ ...theme, ...{ modalOpen: modalOpen } }}>
    <GlobalStyles />
    {children}
  </ThemeProvider>
);

export default React.memo(Theme);
