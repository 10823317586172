import styled from "styled-components";

import { ImageBackground } from "#src/components";
export const StyledImageBackground = styled(ImageBackground)`
  border-radius: 100%;
  width: 220px;
  height: 220px;
  min-height: 0;
  aspect-ratio: 1;
  margin-top: -0.5rem;
  background-size: cover;
  background-position: top;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    width: 100px;
    height: 100px;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  gap: 4rem;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    gap: 2rem;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    gap: 0.5rem;
  }
  p {
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 1rem;
  }
`;
