import styled from "styled-components";
import { lighten } from "#src/Helpers";
import { NavLink } from "react-router-dom";
import { Icon } from "../Icon";

export const ChildrenContainer = styled.span`
  position: relative;
  ${({ theme }) => theme.flex()};
  gap: 9px;
`;

export const Ripple = styled.span`
  background-color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  display: block;
  border-radius: 999px;
  transform-origin: center center;
  opacity: 0;
  pointer-events: none;
`;

export const StyledButton = styled.button`
  cursor: pointer;
  position: relative;
  min-height: 56px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding: 15px 24px;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s ${({ theme }) => theme.ease};
  color: ${({ theme }) => theme.colors.title};
  background-color: ${({ theme }) => theme.colors.primary};
  border: 1px solid;
  border-color: transparent;
  &:hover {
    background-color: ${({ theme }) => lighten(theme.colors.primary, 0.1)};
  }
  &:disabled {
    cursor: default;
    pointer-events: none;
    background-color: ${({ theme }) => lighten(theme.colors.secondary, 0.1)};
    color: ${({ theme }) => theme.colors.secondary};
    ${Ripple} {
      display: none;
    }
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    min-height: 48px;
    padding: 15px;
  }
`;

export const A = styled.a`
  display: block;
  button {
    width: 100%;
  }
`;

export const StyledNavLink = styled(NavLink)`
  display: block;
  button {
    width: 100%;
  }
`;

export const StyledIcon = styled(Icon)`
`;
