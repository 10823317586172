import React, { useState } from "react";
import { FAQContainer, FAQWrapper, Question, Answer, PlusMinus } from "./FAQ.styles";
import AnimateHeight from "react-animate-height";

const FAQ = ({ data }) => {
  const [clicked, setClicked] = useState(null);

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  return (
    <FAQContainer>
      {data.map((item, index) => {
        return (
          <FAQWrapper key={index}>
            <Question onClick={() => toggle(index)} active={clicked === index}>
              <h4>{item.question}</h4>
              <PlusMinus minus={clicked === index} />
            </Question>
            <AnimateHeight duration={300} height={clicked === index ? `auto` : 0}>
              <Answer>
                <p>{item.answer}</p>
              </Answer>
            </AnimateHeight>
          </FAQWrapper>
        );
      })}
    </FAQContainer>
  );
};

export default React.memo(FAQ);
