import React from "react";
import {
  Container,
  CommonHero,
  SeparatorSection,
  LeftRightLayout,
  AnimatedText,
  InfoBoxGrid,
  PreFooter,
  ShareMeta,
} from "#src/components";
import { entitymanagementData as data } from "#src/data";

const EntityManagement = () => {
  const { hero, sections } = data;
  return (
    <Container>
      <ShareMeta title={hero.title} description={hero.text} type="website" />
      <CommonHero center imageProps={{ screen: 1 }} {...hero} />
      <SeparatorSection>
        <LeftRightLayout
          imgHeight="560px"
          left={
            <>
              <h3 className="desktop:pr-10">
                <AnimatedText text={sections[0].title} />
              </h3>
              <h4>
                <AnimatedText text={sections[0].subtitle} />
              </h4>
              <p>
                <AnimatedText block text={sections[0].text} />
              </p>
            </>
          }
          right={<img src={sections[0].image} alt={sections[0].title} />}
        />
      </SeparatorSection>
      <SeparatorSection>
        <h3 className="center mb-10">
          <AnimatedText text={sections[1].title} />
        </h3>
        <InfoBoxGrid boxes={sections[1].features} />
      </SeparatorSection>
      <PreFooter />
    </Container>
  );
};

export default EntityManagement;
