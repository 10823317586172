import styled from "styled-components";
import { motion } from "framer-motion";

export const HeroContainer = styled.section`
  min-height: 100vh;
  padding-top: 0;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  padding: 32px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 128px 16px;
    margin-top: 0;
  }
  &:before {
    ${({ theme }) => theme.pseudoFull};
    ${({ theme, bg }) => theme.bgImg(bg)};
    z-index: 0;
    opacity: 0.3;
  }
  &:after {
    ${({ theme }) => theme.pseudoFull};
    ${({ theme }) => theme.heroBg(true)};
    z-index: 0;
  }
  h1 {
    max-width: 900px;
    font-weight: 400;
    margin-bottom: 25px;
    padding-top: 60px;
    width: 100%;
    text-align: center;
    text-transform: none;
    padding-top: 0;
    @media (max-width: ${({ theme }) => theme.phone}) {
      text-align: left;
      padding-right: 1rem;
    }
  }
  p {
    width: 100%;
    max-width: 600px;
    line-height: 1.4;
    color: ${({ theme }) => theme.colors.secondary};
    text-align: center;
    @media (max-width: ${({ theme }) => theme.phone}) {
      text-align: left;
    }
  }
`;

export const ArrowNav = styled.div`
  cursor: pointer;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  position: absolute;
  bottom: 50px;
  gap: 10px;
  @media (max-width: ${({ theme }) => theme.phone}) {
    align-items: center;
    align-self: flex-start;
    flex-direction: row-reverse;
  }
  span {
    color: ${({ theme }) => theme.colors.title};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: none;
  }
  svg {
    color: ${({ theme }) => theme.colors.title};
    transition: translate 0.3s ${({ theme }) => theme.ease};
  }
  &:hover {
    span {
      opacity: 1;
    }
    svg {
      translate: 0 10px;
    }
  }
`;
export const ChildrenContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  perspective: 1500px;
  @media (max-width: ${({ theme }) => theme.phone}) {
    align-items: flex-start;
  }
`;
export const Doohickey1 = styled(motion.div)`
  width: 88.531px;
  height: 86.459px;
  position: absolute;
  rotate: -31.338deg;
  top: 20vh;
  right: 10vw;
  filter: blur(12.5px);
  ${({ theme }) => theme.bgImg("/images/elements/doohickey1.svg")};
`;
export const Doohickey2 = styled(Doohickey1)`
  width: 88.352px;
  height: 63.935px;
  rotate: 31.338deg;
  bottom: 20vh;
  top: auto;
  left: 10vw;
  filter: blur(12.5px);
  ${({ theme }) => theme.bgImg("/images/elements/doohickey2.svg")};
`;
