import { StyledSocialIcons } from './styles/SocialIcons.styled';

export default function SocialIcons() {
  return (
    <StyledSocialIcons>
      <li>
        <a href="https://www.facebook.com/binderrofficial"  aria-label="Facebook" target={'_blank'} rel="noreferrer" >
          <svg className="svg-social-icon" xmlns="http://www.w3.org/2000/svg" width="9" height="17" viewBox="0 0 9 17">
            <g fill="none" fillRule="evenodd">
              <g fill="#ffffff" fillRule="nonzero">
                <g>
                  <g>
                    <g>
                      <g>
                        <path d="M5.625 5.844V3.719c0-.587.504-1.063 1.125-1.063h1.125V0h-2.25C3.761 0 2.25 1.427 2.25 3.188v2.656H0V8.5h2.25V17h3.375V8.5h2.25L9 5.844H5.625z" transform="translate(-1028 -3973) translate(0 3708) translate(1012 73) translate(0 180) translate(16 12)" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>
      </li>
      {/* <li>
        <a href="https://www.instagram.com/binderrofficial/" target={'_blank'} rel="noreferrer">
          <svg className="svg-social-icon" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
            <g fill="none" fillRule="evenodd">
              <g fill="#ffffff" fillRule="nonzero">
                <g>
                  <g>
                    <g>
                      <g transform="translate(-1074 -3973) translate(0 3708) translate(1012 73) translate(50 180) translate(12 12)">
                        <path d="M11.688 0H5.311C2.38 0 0 2.379 0 5.313v6.375C0 14.62 2.379 17 5.313 17h6.375C14.62 17 17 14.621 17 11.687V5.314C17 2.378 14.621 0 11.687 0zm3.718 11.688c0 2.05-1.668 3.718-3.719 3.718H5.314c-2.051 0-3.72-1.668-3.72-3.719V5.314c0-2.051 1.669-3.72 3.72-3.72h6.375c2.05 0 3.718 1.669 3.718 3.72v6.375z" />
                        <path d="M8.5 4.25c-2.347 0-4.25 1.903-4.25 4.25s1.903 4.25 4.25 4.25 4.25-1.903 4.25-4.25-1.903-4.25-4.25-4.25zm0 6.906c-1.464 0-2.656-1.192-2.656-2.656 0-1.465 1.192-2.656 2.656-2.656 1.464 0 2.656 1.19 2.656 2.656 0 1.464-1.192 2.656-2.656 2.656z" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>
      </li> */}
      <li>
        <a href="https://www.linkedin.com/company/binderr/" target={'_blank'} rel="noreferrer" aria-label="LinkedIn" >
          <svg className="svg-social-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
            <g fill="none" fillRule="evenodd">
              <g fill="#ffffff" fillRule="nonzero">
                <g>
                  <g>
                    <g>
                      <g>
                        <path d="M15.6 9.198V15h-3.345V9.584c0-1.362-.482-2.288-1.693-2.288-.925 0-1.471.627-1.715 1.23-.088.217-.114.521-.114.821V15H5.39s.045-9.17 0-10.12h3.342v1.435c-.004.012-.014.021-.018.034h.018v-.034c.447-.69 1.238-1.672 3.014-1.672 2.205 0 3.853 1.446 3.853 4.556zM1.892 0C.75 0 0 .755 0 1.75 0 2.72.727 3.5 1.85 3.5h.02c1.168 0 1.892-.781 1.892-1.75C3.74.755 3.038 0 1.892 0zM.2 15h3.343V4.879H.2V15z" transform="translate(-1174 -3974) translate(0 3708) translate(1012 73) translate(150 180) translate(12 13)" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>
      </li>
    </StyledSocialIcons>
  )
}
