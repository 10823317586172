import React from "react";
import { HeroContainer, ArrowNav, ChildrenContainer, Doohickey1, Doohickey2 } from "./FullPageHero.styles";
import { Icon } from "#src/components";

const FullPageHero = ({ children, arrowtext, doohickeys, img, ...other }) => {
  const handleClick = () => {
    window.scrollTo({ top: window.innerHeight, behavior: "smooth" });
  };
  let isSafari =
    navigator.vendor.match(/apple/i) &&
    !navigator.userAgent.match(/crios/i) &&
    !navigator.userAgent.match(/fxios/i) &&
    !navigator.userAgent.match(/Opera|OPT\//);
  return (
    <HeroContainer bg={img} {...other}>
      <ChildrenContainer>{children}</ChildrenContainer>
      {arrowtext && (
        <ArrowNav onClick={handleClick}>
          <span>{arrowtext}</span>
          <Icon icon="ARROW_BOTTOM" />
        </ArrowNav>
      )}
      {doohickeys && !isSafari && (
        <>
          <Doohickey1 />
          <Doohickey2 />
        </>
      )}
    </HeroContainer>
  );
};

export default React.memo(FullPageHero);
