import styled from "styled-components";
export const Image = styled.div`
  background-size: contain;
  background-position: ${({ position }) => (position ? position : "center")};
  background-image: url(${({ url }) => (url ? url : "")});
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "400px")};
`;
