import styled from "styled-components";
import { AnimationContainer, CenterLayout } from "#src/components";
import { BgIcon } from "../BgIcon";
import { motion } from "framer-motion";

const colw = 120;

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 64px 0 64px 0;
    padding: 222px 0 164px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: ${colw}px;
  }
`;

export const Line = styled.div`
  width: 1px;
  position: absolute;
  height: 100%;
  background: linear-gradient(rgba(93, 85, 240, 0.68) 0%, rgba(93, 85, 240, 0.58) 90%, rgba(93, 85, 240, 0) 100%);
  transform: translateY(-5%);
  opacity: 0.4;
  justify-self: center;
  display: flex;
  justify-content: center;
  &:before {
    content: "";
    position: absolute;
    width: 800px;
    height: 300px;
    bottom: 100%;
    background-image: url("/images/bg_struct.png");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
  }
`;
export const ImageWrapper = styled(motion.div)`
  ${({ theme }) => theme.gradientStroke("24px")};
  position: absolute;
  border-radius: 24px;
  width: 100%;
  height: 100%;
  background-size: cover;
  perspective: 1000px;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    ${({ theme }) => theme.gradientStroke("12px")};
    border-radius: 12px;
  }
`;
export const ImagesWrapper = styled(motion.div)`
  position: relative;
  width: 100%;
  aspect-ratio: 550 / 355;
  > div {
    width: 100%;
    height: 100%;
  }
`;
export const StickyWrapper = styled.div`
  flex: 1 1 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 550 / 355;
  height: auto;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    margin-bottom: 32px;
    z-index: 1;
    position: relative;
    max-width: 600px;
  }
  @media (min-width: ${({ theme }) => theme.tablet}) {
    position: sticky;
    border-radius: 24px;
    top: calc(50% - 200px);
    padding-right: 32px;
  }
`;
export const TimelineItemsContainer = styled.div`
  flex: 1 1 500px;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 50%;
  transition: transform 1s cubic-bezier(0.2, 0.65, 0.3, 0.9);

  @media (min-width: ${({ theme }) => theme.tablet}) {
    gap: 92px;
  }
`;
export const StyledCenterLayout = styled(CenterLayout)`
  & {
    position: relative;
  }
`;

export const ItemWrapper = styled.div`
  position: relative;
  scroll-snap-type: y mandatory;
  scroll-snap-align: center;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    margin-bottom: 64px;
  }
`;
export const FakeIntersectionContainer = styled.div`
  position: relative;
  display: grid;
  /* height: 150vh; */
`;
export const FakeIntersection = styled.div`
  position: relative;
`;

export const LiIcon = styled.img`
  width: 19px;
  height: 19px;
  object-fit: contain;
  flex: 0 0 19px;
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  /* opacity: ${({ active }) => (active ? 1 : 0.4)}; */
  /* transition: opacity 0.3s; */
  h4 {
    font-size: 30px;
    line-height: 34px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.title};
    padding-right: 32px;
  }
`;

export const Ul = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;
export const Li = styled(AnimationContainer)`
  display: flex;
  gap: 12px;
  font-weight: 300;
  padding-right: 24px;
  color: ${({ theme }) => theme.colors.secondary};
  &:last-child {
    margin-top: 12px;
  }
  a {
    text-decoration: underline;
  }
`;
export const StyledBgIcon = styled(BgIcon)`
  @media (min-width: ${({ theme }) => theme.tablet}) {
    position: absolute;
    translate: -${colw / 2 + 23}px -7px;
  }
  margin-bottom: 24px;
`;
export const UiElement = styled.div`
  /* border: 1px solid red; */
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  transform: translateZ(100px);
`;
