import { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { StyledButton, ChildrenContainer, Ripple, A, StyledNavLink, StyledIcon } from "./Button.styled";
import { rgba } from "#src/Helpers";
import { ROUTES } from "#src/common/constants";
import { Icon, ModalVideo } from "#src/components";
import { DispatchContext } from "../../context";
import { commonData } from "#src/data";

const { bookADemoButtonText, watchVideoButtonText } = commonData.buttons;

const Button = ({ children, className, disabled, href, to, leftIcon, rightIcon, ...other }) => {
  const btnRef = useRef(null);
  const [bounds, setBounds] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [mouseDown, setMouseDown] = useState(false);
  useEffect(() => {
    setBounds(btnRef.current.getBoundingClientRect());
  }, [coords]);
  const handleMouseDown = (e) => {
    setCoords({ x: Math.round(e.clientX - bounds.left), y: Math.round(e.clientY - bounds.top) });
    setMouseDown(true);
  };
  const rippleStyles = {
    position: "absolute",
    left: `${coords.x - bounds?.width}px`,
    top: `${coords.y - bounds?.width}px`,
    height: `${bounds?.width * 2}px`,
    width: `${bounds?.width * 2}px`,
    opacity: mouseDown ? 1 : 0,
    transform: mouseDown ? "scale(0)" : "scale(1)",
    transition: mouseDown ? "none" : "transform 0.6s ease, opacity 0.4s ease 0.2s",
  };
  const content = (
    <StyledButton
      type="button"
      className={className}
      onMouseDown={handleMouseDown}
      onMouseUp={() => setMouseDown(false)}
      ref={btnRef}
      disabled={disabled}
      {...other}
    >
      <Ripple mouseDown={mouseDown} style={rippleStyles} />
      <ChildrenContainer>
        {leftIcon && <StyledIcon icon={leftIcon} />}
        {children}
        {rightIcon && <StyledIcon icon={rightIcon} />}
      </ChildrenContainer>
    </StyledButton>
  );
  if (!!href) {
    return (
      <A className={className} href={href} {...other}>
        {content}
      </A>
    );
  }
  if (!!to) {
    return (
      <StyledNavLink className={className} to={to} {...other}>
        {content}
      </StyledNavLink>
    );
  }
  return content;
};

export default Button;

export const PrimaryActionButton = styled(Button)``;
export const DominantActionButton = styled(Button)``;
export const StandardActionButton = styled(Button)`
  background-color: transparent;
  border-color: ${({ theme }) => rgba(theme.colors.border, 0.25)};
  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
    background-color: transparent;
  }
  ${Ripple} {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  &:disabled {
    background-color: transparent;
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
  }
  ${StyledIcon} {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
export const DangerActionButton = styled(StandardActionButton)`
  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }
  ${Ripple} {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;
export const LowPriorityActionButton = styled(StandardActionButton)`
  color: #8193ae;
  border-color: ${({ theme }) => rgba(theme.colors.border, 0.15)};
  &:hover {
    border-color: ${({ theme }) => rgba(theme.colors.border, 0.7)};
  }
  ${Ripple} {
    background-color: ${({ theme }) => rgba(theme.colors.border, 0.7)};
  }
`;
export const AltActionButton = styled(LowPriorityActionButton)`
  &:hover {
    color: #fff;
    border-color: ${({ theme }) => theme.colors.primary};
  }
  ${Ripple} {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;
export const LearnMoreButtonStyles = styled(AltActionButton)`
  padding: 0;
  border-color: transparent;
  overflow: visible;
  ${StyledIcon} {
    color: currentColor;
    transition: translate 0.3s ${({ theme }) => theme.ease};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border-color: transparent;
    ${StyledIcon} {
      translate: 5px 0;
    }
  }
  ${Ripple} {
    background-color: transparent;
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    min-height: 48px;
    padding: 0;
    width: auto !important;
  }
`;

export const BookADemoButton = ({ text = bookADemoButtonText }) => (
  <Button to={ROUTES.DEMO.path} leftIcon="CALENDAR">
    {text}
  </Button>
);

export const LearnMoreButton = ({ text = "Learn more", icon = "ARROW_RIGHT", ...props }) => (
  <LearnMoreButtonStyles {...props} rightIcon={icon}>
    {text}
  </LearnMoreButtonStyles>
);

export const WatchVideoButton = ({ text = watchVideoButtonText }) => {
  const dispatch = useContext(DispatchContext);
  return (
    <StandardActionButton
      onClick={() => {
        dispatch({ type: "setModalOpen", modalOpen: true });
        dispatch({
          type: "setModalChildren",
          modalChildren: <ModalVideo />,
        });
      }}
    >
      <Icon icon="PLAY" />
      {text}
    </StandardActionButton>
  );
};
