import styled from "styled-components"

export const Wrapper = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 12px;
  background: #2a3b75;
  transform-origin: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 0 0 46px;
`;
export const IconBackground = styled.div`
  background: linear-gradient(143deg, #5d55f0 9.3%, #4139d6 89.84%);
  width: 100%;
  height: 100%;
  position: absolute;
`;
export const Icon = styled.img`
  width: 19px;
  height: 19px;
  object-fit: contain;
  position: absolute;
`;