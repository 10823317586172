import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function newTab(e, url, track = false) {
  if (e) e.preventDefault();
  if (track) window.lintrk("track", { conversion_id: 10961938 });
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
}

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export function polarToCartesian(radius, angle, unit = "deg") {
  let f;
  if (unit === "deg") f = ((angle - 90) * Math.PI) / 180;
  else if (unit === "rad") f = (angle - 0.5) * Math.PI;
  else f = (angle * Math.PI * 2) / unit - Math.PI / 2;
  return { x: Math.round(radius * Math.cos(f) * 10) / 10, y: Math.round(radius * Math.sin(f) * 10) / 10 };
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

export const formatSlug = (slug) => slug.replace("binderr-blog/", "");

export function searchBySlug(arr, searchTerm, ignoreString) {
  return arr.find((obj) => obj.slug.replace(ignoreString, "") === searchTerm);
}

export function getObjectsWithExcludedId(objectsArray, excludedId, numberOfObjects = 3) {
  let result = [];
  let count = 0;

  for (let i = 0; i < objectsArray.length && count < numberOfObjects; i++) {
    if (objectsArray[i].id !== excludedId) {
      result.push(objectsArray[i]);
      count++;
    }
  }

  return result;
}

export function sortByDate(arr) {
  const sorted = arr.sort(function (a, b) {
    var dateA = new Date(a?.publishDate);
    var dateB = new Date(b?.publishDate);
    return dateB - dateA;
  });
  return sorted;
}

export function titleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word[0].toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function rgba(hex, opacity) {
  const c = hexToRgb(hex);
  return `rgba(${c.r}, ${c.g}, ${c.b}, ${opacity})`;
}

export function colorChannelMixer(a, b, amount) {
  const ch1 = a * (1 - amount);
  const ch2 = b * amount;
  return Number(ch1 + ch2);
}

export function mix(color1, color2, amount = 0.5, opacity = 1) {
  const c1 = hexToRgb(color1);
  const c2 = hexToRgb(color2);
  const r = colorChannelMixer(c1.r, c2.r, amount);
  const g = colorChannelMixer(c1.g, c2.g, amount);
  const b = colorChannelMixer(c1.b, c2.b, amount);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function darken(color, amount) {
  return mix(color, "#000000", amount);
}
export function lighten(color, amount) {
  return mix(color, "#ffffff", amount);
}

function MurmurHash3(string) {
  let i = 0;
  let hash = 1779033703 ^ string.length; // declare hash variable here
  for (i; i < string.length; i++) {
    let bitwise_xor_from_character = hash ^ string.charCodeAt(i);
    hash = Math.imul(bitwise_xor_from_character, 3432918353);
    hash = (hash << 13) | (hash >>> 19);
  }
  return () => {
    // Return the hash that you can use as a seed
    hash = Math.imul(hash ^ (hash >>> 16), 2246822507);
    hash = Math.imul(hash ^ (hash >>> 13), 3266489909);
    return (hash ^= hash >>> 16) >>> 0;
  };
}

function Mulberry32(string) {
  return () => {
    let for_bit32_mul = (string += 0x6d2b79f5);
    let cast32_one = for_bit32_mul ^ (for_bit32_mul >>> 15);
    let cast32_two = for_bit32_mul | 1;
    for_bit32_mul = Math.imul(cast32_one, cast32_two);
    for_bit32_mul ^= for_bit32_mul + Math.imul(for_bit32_mul ^ (for_bit32_mul >>> 7), for_bit32_mul | 61);
    return ((for_bit32_mul ^ (for_bit32_mul >>> 14)) >>> 0) / 4294967296;
  };
}

export function reverseString(str) {
  return str.split("").reverse().join("");
}

export const randomize = (min, max, seed = 1) => {
  let s = MurmurHash3(seed);
  let rng = Mulberry32(s());
  const r = Math.floor(rng() * (max - min + 1) + min);
  return r;
};

export function randomValue(min, max) {
  return Math.random() * (max - min) + min;
}

export function generateSrcset(imagePath, sizes = [400, 800], extension = "avif") {
  const path = imagePath.substring(0, imagePath.lastIndexOf("/") + 1);
  const filename = imagePath.substring(imagePath.lastIndexOf("/") + 1).split(".")[0];
  const srcset = sizes.map((size) => `${path}${filename}-${size}w.${extension} ${size}w`).join(", ");
  const srcSetWithOriginal = `${srcset}, ${imagePath} 1000w`;
  return srcSetWithOriginal;
}

export function transposeNumber(inputNum, inputMin, inputMax, outputMin, outputMax) {
  const normalizedInput = (inputNum - inputMin) / (inputMax - inputMin);
  const outputNum = outputMin + normalizedInput * (outputMax - outputMin);
  return outputNum;
}

export function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}