import styled from "styled-components";
import { AnimationContainer } from "../";
import { Button } from "#src/components";
import { rgba } from "#src/Helpers";

export const CardButton = styled(Button)`
  position: relative;
  transform: translate3d(0, 100px, 0);
  @media (max-width: ${({ theme }) => theme.tablet}) {
    transform: translate3d(0, 0, 0);
  }
  transition: transform 0.4s ${({ theme }) => theme.ease};
  z-index: 2;
  opacity: 0.99;
`;

export const Wrapper = styled(AnimationContainer)`
  z-index: 1;
  min-height: 496px;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    min-height: 440px;
  }
  overflow: hidden;
  ${({ theme }) => theme.flex("column", "flex-end", "center")};
  ${({ theme }) => theme.gradientStroke()};
  &:before {
    z-index: 1;
  }
  img {
    width: 666px;
    z-index: 0;
    position: absolute;
    transition: transform 0.4s ${({ theme }) => theme.ease};
    transform: translate3d(20px, 150px, 0) rotateX(45deg) rotateZ(-45deg) scale(0.9);
  }
  &:hover {
    ${CardButton} {
      transform: translate3d(0, 0, 0);
    }
    img {
      transform: translate3d(20px, 145px, 0) rotateX(45deg) rotateZ(-45deg) scale(0.88);
    }
  }
`;

export const Content = styled.div`
  ${({ theme }) => theme.flex("column", "flex-start", "stretch")};
  transform: translate3d(0, 0, 999px);
  /* thanx safari */
  padding: 32px;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    padding: 32px 24px;
  }
  gap: 18px;
  flex: 1;
  position: relative;
  z-index: 1;
  background: radial-gradient(
      160% 135% at -10% -65%,
      ${({ theme }) => rgba(theme.colors.primary, 0.5)} 0%,
      ${({ theme }) => rgba(theme.colors.primary, 0)} 100%
    ),
    radial-gradient(180.99% 143.45% at 80% 180%, rgba(0, 0, 0, 0) 80%, #00141f 100%);
  p {
    flex: 1;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;
