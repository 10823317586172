// import React, { useContext } from "react";
import React from "react";
import {
  Container,
  CommonHero,
  SeparatorSection,
  LeftRightLayout,
  AnimatedText,
  InfoBoxGrid,
  PreFooter,
  ShareMeta,
  Button,
} from "#src/components";
import { reportingData as data } from "#src/data";
// import { DispatchContext } from "#src/context";
// import { StyledIframe } from "./Reporting.styles";

const Reporting = () => {
  const { hero, sections } = data;
  // const dispatch = useContext(DispatchContext);
  return (
    <Container>
      <ShareMeta title={hero.title} description={hero.text} type="website" />
      <CommonHero center imageProps={{ screen: 1 }} {...hero} />
      <SeparatorSection>
        <LeftRightLayout
          imgHeight="560px"
          left={
            <>
              <h3 className="desktop:pr-10">
                <AnimatedText text={sections[0].title} />
              </h3>
              <h4>
                <AnimatedText text={sections[0].subtitle} />
              </h4>
              <Button
                // onClick={() => {
                //   dispatch({ type: "setModalOpen", modalOpen: true });
                //   dispatch({
                //     type: "setModalChildren",
                //     modalChildren: (
                //       <StyledIframe
                //         title={"hubspot form"}
                //         src={"https://share-eu1.hsforms.com/1G4JWA9GKQPagA10LfUWb8gfanqm"}
                //       />
                //     ),
                //   });
                // }}
                href="https://share-eu1.hsforms.com/1G4JWA9GKQPagA10LfUWb8gfanqm"
                target="_blank"
              >
                Join our mailing list
              </Button>
            </>
          }
          right={<img src={sections[0].image} alt={sections[0].title} />}
        />
      </SeparatorSection>
      <SeparatorSection>
        <LeftRightLayout
          imgHeight="560px"
          reverse
          left={
            <>
              <h3 className="desktop:pr-10">
                <AnimatedText text={sections[1].title} />
              </h3>
              <p>
                <AnimatedText block text={sections[1].text} />
              </p>
            </>
          }
          right={<img src={sections[1].image} alt={sections[1].title} />}
        />
      </SeparatorSection>
      <SeparatorSection>
        <h3 className="center mb-10">
          <AnimatedText text={sections[2].title} />
        </h3>
        <InfoBoxGrid boxes={sections[2].features} />
      </SeparatorSection>
      <PreFooter />
    </Container>
  );
};

export default Reporting;
