import styled, { css } from "styled-components";

export const IconWrapper = styled.span`
  color: ${({ color }) => color || "currentColor"};
  display: ${({ block }) => (block ? "block" : "inline-block")};
  ${({ size }) =>
    size &&
    css`
      svg {
        width: ${size};
        height: ${size};
      }
    `}
`;

