import React from "react";
import { Helmet } from "react-helmet";
import { ROUTES } from "#src/common/constants";
import { useLocation } from "react-router-dom";

export function ShareMeta({
  title,
  type = "article",
  img = "/images/og_img.webp",
  url = "http://www.binderr.com",
  description = "Streamline your workflow and supercharge your growth with a single, centralised platform designed to manage and automate daily tasks.",
}) {
  const { pathname } = useLocation();
  function findRouteByPath(path, routes) {
    for (const key in routes) {
      if (routes[key].path === path) {
        return routes[key];
      }
    }
    return null; // Return null if route not found
  }
  const name = "Binderr";
  const route = findRouteByPath(pathname, ROUTES);
  const tagline = "Client onboarding and compliance redefined";
  return (
    <Helmet>
      {type === "article" ? (
        <title>{`${name} | ${title ? title : tagline}`}</title>
      ) : (
        <title>{`${pathname === ROUTES.HOME.path ? `${name} - ` : ` ${route?.title} - `}${
          title ? title : tagline
        }`}</title>
      )}
      <meta name="description" content={description} />
      <meta property="og:title" content={`${name} | ${title ? title : tagline}`} />
      <meta property="og:type" content={type} />
      <meta property="og:image" content={img} />
      <meta property="og:url" content={url} />
      <meta name="twitter:card" content="summary_large_image" />

      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={name} />
      <meta name="twitter:image:alt" content={`${name} | ${tagline}`} />

      {/* <meta property="fb:app_id" content="your_app_id" /> */}
      {/* <meta name="twitter:site" content="@website-username" /> */}
    </Helmet>
  );
}
