import styled from "styled-components";
import { AnimationContainer } from "../AnimationContainer";

const blurryCircle = (rgb) => `radial-gradient(
  circle,
  rgba(${rgb}, 0.2) 0%,
  rgba(${rgb}, 0.1) 20%,
  rgba(${rgb}, 0.06) 40%,
  rgba(${rgb}, 0) 60%
)`
const r = 800

export const Card = styled(AnimationContainer)`
  border: 1px solid rgba(0, 92, 138, 0.167);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    padding: 32px 16px;
    button {
      width: 100%;
    }
  }
  background-color: rgba(1, 37, 55, 0.1);
  box-shadow: 10px 10px 60px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  border-radius: 16px;
  width: 100%;
  background-repeat: no-repeat;
  max-width: ${({ theme }) => theme.maxWidth};
  background-position: bottom -${r}px left -${r}px, top -${r}px right -${r}px;
  background-size: ${2*r}px ${2*r}px, ${2*r}px ${2*r}px;
  background-image: ${blurryCircle('255, 102, 148')}, ${blurryCircle('108, 220, 215')};
`;

export const Content = styled(AnimationContainer)`
  max-width: 820px;
  padding: 96px;
  gap: 64px;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    padding: 0;
    gap: 32px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
export const Copy = styled(AnimationContainer)`
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 32px;
`;
