import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { animate, motion, useMotionTemplate, useMotionValue, useTransform } from "framer-motion";

const Container = styled.div`
  position: relative;
  perspective: 1600px;
`;

const RotationWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  pointer-events: none;
  position: relative;
`;

const CardWrapper = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Sheen = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  pointer-events: none;
  border-radius: 20px;
`;

const HoverWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
`;

const BottomLayerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  transform: translateZ(-500px) scale(1.5);
  border-radius: 16px;
`;
const TopLayerContainer = styled(BottomLayerContainer)`
  transform: translateZ(50px);
  border-radius: 0;
`;

const fadeOffset = 100;
const z = 100
const FadeBorder = styled.div`
  position: absolute;
  width: calc(100% + ${({ fade }) => (fade ? fade * fadeOffset*1.2 : 0)}px);
  height: calc(100% + ${({ fade }) => (fade ? fade * fadeOffset : 0)}px);
  top: -${({ fade }) => (fade ? fade * (fadeOffset / 2) : 0)}px;
  transform: translateZ(-${({ fade }) => (fade ? fade * z : 500)}px);
  border-radius: ${({ r, fade }) => (r ? r + (fade && fade * 6) : 16)}px;
  border: 1px solid #5d55f0;
  opacity: ${({ fade }) => (fade ? 0.3 - fade * 0.11 : 0.3)};
  box-shadow: 0 0 30px 0 rgba(13 2 24 / 0.22);
  background: linear-gradient(181deg, rgba(11 8 17 / 0.2) 31.95%, rgba(11 8 26 / 0.4) 88.47%);
`;

const RotatingCard = ({ children, dampen = 100, bottomLayer, topLayer, fadeBorder = 3 }) => {
  // mouse position
  const centerX = typeof window !== "undefined" ? window.innerWidth / 2 : 0;
  const centerY = typeof window !== "undefined" ? window.innerHeight / 2 : 0;
  const mouseX = useMotionValue(centerX);
  const mouseY = useMotionValue(centerY);

  // a reference for our animated card element
  const cardRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);

  // rotation
  const rotateX = useTransform(mouseY, (newMouseY) => {
    if (!cardRef.current) return 0;
    const rect = cardRef.current.getBoundingClientRect();
    const newRotateX = newMouseY - rect.top - rect.height / 2;
    return -newRotateX / dampen;
  });
  const rotateY = useTransform(mouseX, (newMouseX) => {
    if (!cardRef.current) return 0;
    const rect = cardRef.current.getBoundingClientRect();
    const newRotateY = newMouseX - rect.left - rect.width / 2;
    return newRotateY / dampen;
  });

  // sheen
  const diagonalMovement = useTransform([rotateX, rotateY], ([newRotateX, newRotateY]) => {
    const position = newRotateX + newRotateY;
    return position;
  });
  const sheenPosition = useTransform(diagonalMovement, [-5, 5], [-100, 200]);
  const sheenOpacity = useTransform(sheenPosition, [-250, 50, 250], [0, 0.05, 0]);
  const sheenGradient = useMotionTemplate`linear-gradient(
    55deg,
    transparent,
    rgba(103 116 244 / ${sheenOpacity}) ${sheenPosition}%,
    transparent)`;

  // handle mouse move on document
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isHovering) {
        // animate mouse x and y
        animate(mouseX, e.clientX);
        animate(mouseY, e.clientY);
      } else {
        animate(mouseX, centerX, { duration: 1 });
        animate(mouseY, centerY, { duration: 1 });
      }
    };
    if (typeof window === "undefined") return;
    // recalculate grid on resize
    window.addEventListener("mousemove", handleMouseMove);
    // cleanup
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isHovering, mouseX, mouseY]);
  const fadeBorderArray = Array.from({ length: fadeBorder }, (_, index) => index);
  return (
    <Container>
      <RotationWrapper style={{ rotateX, rotateY }}>
        {!!bottomLayer && <>{bottomLayer}</>}
        {fadeBorderArray.map((_, index) => (
          <FadeBorder key={index} fade={index + 1} r={16} />
        ))}
        <CardWrapper ref={cardRef}>
          <motion.div className="card-content">
            {children}
            <Sheen style={{ backgroundImage: sheenGradient }} />
          </motion.div>
        </CardWrapper>
        {!!topLayer && <>{topLayer}</>}
      </RotationWrapper>
      <HoverWrapper onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} />
    </Container>
  );
};

export default RotatingCard;
