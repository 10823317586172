import React from "react";
import {
  Container,
  SeparatorSection,
  CommonHero,
  AnimatedText,
  LeftRightLayout,
  FeatureList,
  Logos,
  PreFooter,
  ThreeColumnLayout,
  FAQ,
  GradientSeparator,
  ShareMeta,
  CenterLayout
} from "#src/components";
import { kycamlData as data } from "#src/data";
import { useMedia } from "#src/hooks";

export default function KYCAML() {
  const { hero, sections, prefooter } = data;
  const { isMobile } = useMedia();
  return (
    <Container>
      <ShareMeta title={hero.title} description={hero.text} type="website" />
      <CommonHero {...hero} imageProps={{ screen: 1 }} columns="1.1fr 0.9fr" />
      <SeparatorSection id="cra">
        <CenterLayout>
          <h2 className={isMobile ? "center" : "center mb-5"}>
            <AnimatedText text={sections[0].title} />
          </h2>
        </CenterLayout>
        <ThreeColumnLayout
          areas={isMobile ? '"c2" "c1" "c3"' : undefined}
          columns={[
            <FeatureList alt features={sections[0].features.slice(0, 2)} />,
            <img src={sections[0].image} alt={sections[0].title} />,
            <FeatureList alt features={sections[0].features.slice(2, 4)} />,
          ]}
        ></ThreeColumnLayout>
      </SeparatorSection>
      <SeparatorSection id="aml">
        <LeftRightLayout
          columns="0.9fr 1.1fr"
          left={
            <>
              <h3 className="mb-10 desktop:pr-10">
                <AnimatedText text={sections[1].title} />
              </h3>
              <FeatureList features={sections[1].features} />
            </>
          }
          right={<img src={sections[1].image} alt={sections[1].title} />}
        />
      </SeparatorSection>
      <SeparatorSection>
        <LeftRightLayout
          reverse
          columns="0.9fr 1.1fr"
          gap="100px"
          left={
            <>
              <h3>
                <AnimatedText text={sections[2].title} />
              </h3>
              <p className="desktop:pr-20">
                <AnimatedText block text={sections[2].text} />
              </p>
              <Logos logos={sections[2].logos}  />
            </>
          }
          right={<img src={sections[2].image} alt={sections[2].title} />}
        />
      </SeparatorSection>
      <SeparatorSection id="kyc">
        <LeftRightLayout
          gap="100px"
          left={
            <>
              <h3 className="mb-10 desktop:pr-10">
                <AnimatedText text={sections[3].title} />
              </h3>
              <FeatureList features={sections[3].features} />
            </>
          }
          right={<img src={sections[3].image} alt={sections[3].title} />}
        />
      </SeparatorSection>
      <PreFooter data={prefooter} />
      <SeparatorSection>
        <h2>
          <AnimatedText text={sections[4].title} />
        </h2>
        <GradientSeparator />
        <FAQ data={sections[4].data} />
      </SeparatorSection>
    </Container>
  );
}
