import React from "react";
import {
  SeparatorSection,
  LeftRightLayout,
  AnimatedText,
  AnimationContainer,
  ImageBackground,
  LearnMoreButton,
  Label,
} from "#src/components";

const LeftRightSection = ({id, label, title, text, link, image, reverse}) => {
  return (
    <SeparatorSection id={`${id}`}>
      <LeftRightLayout
        imgHeight="560px"
        reverse={reverse}
        left={
          <>
            <AnimationContainer>
              <Label text={label} />
            </AnimationContainer>
            <h3 className="desktop:pr-10">
              <AnimatedText text={title} />
            </h3>
            <p>{text}</p>
            {link?.url && <LearnMoreButton href={link.url} text={link.text} />}
          </>
        }
        right={<ImageBackground url={image} />}
      />
    </SeparatorSection>
  );
};

export default LeftRightSection;
