import SocialIcons from "../SocialIcons";
import { Brand } from "../Brand";
import { StyledFooter, Content } from "./Footer.styles";
import { NavLink } from "react-router-dom";
import { AnimationContainer } from "#src/components";

export default function Footer({ menu }) {
  const { productRoutes, companyRoutes } = menu;
  const today = new Date();
  const year = today.getFullYear();
  return (
    <StyledFooter>
      <AnimationContainer delay={0.2}>
        <Content>
          <ul style={{ gridArea: "brand" }}>
            <li>
              <Brand />
            </li>
            <li>Made with 💜 for professional firms</li>
            <li>© {year} Binderr Ltd</li>
          </ul>
          <ul style={{ gridArea: "product" }}>
            <li>Product</li>
            {productRoutes.map((item, index) => (
              <li key={index}>
                <NavLink to={item.path}>{item.title}</NavLink>
              </li>
            ))}
          </ul>
          <ul style={{ gridArea: "company" }}>
            <li>Company</li>
            {companyRoutes.map((item, index) => (
              <li key={index}>
                <NavLink to={item.path}>{item.title}</NavLink>
              </li>
            ))}
          </ul>
          <ul style={{ gridArea: "touch" }}>
            <li>Get in touch</li>
            <li>
              <a className="email-link" href="mailto:support@binderr.com">
                support@binderr.com
              </a>
            </li>
            <li>
              C1, Midland Micro Enterprise Park,<br></br>
              Triq Burmarrad,<br></br>
              Naxxar, Malta
            </li>
            <li>
              <SocialIcons />
            </li>
          </ul>
        </Content>
      </AnimationContainer>
    </StyledFooter>
  );
}
