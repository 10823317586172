import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  outline: 1px solid #FFFFFF10;
`;
export const Item = styled.div`
  display: grid;
  outline: 1px solid #FFFFFF10;
  width: 100%;
  padding: 2rem;
  height: 200px;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: auto;
  }
`;
