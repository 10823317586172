import React, { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import styled, { useTheme } from "styled-components";
import { useInView } from "react-intersection-observer";

const AnimatedDiv = styled(motion.div)`
  will-change: transform, opacity;
  transform-origin: 50% 100%;
`;

export const AnimationContainer = React.memo(
  ({
    children,
    className,
    x,
    y,
    delay,
    disableAnimation,
    onAnimationStart,
    onAnimationComplete,
    customAnimation,
    onClick,
    playOnMount = false,
    ...other
  }) => {
    const ctrls = useAnimation();
    const { animationProps, intersectionProps } = useTheme();
    const { ref, inView } = useInView(intersectionProps);
    useEffect(() => {
      if (inView || playOnMount) {
        ctrls.start("visible");
      } else {
        ctrls.start("hidden");
      }
    }, [ctrls, inView, playOnMount]);

    return (
      <AnimatedDiv
        onClick={onClick}
        className={className}
        ref={ref}
        {...(!disableAnimation && animationProps(ctrls, delay, x, y, customAnimation))}
        onAnimationStart={onAnimationStart}
        onAnimationComplete={onAnimationComplete}
        {...other}
      >
        {children}
      </AnimatedDiv>
    );
  }
);
