import React from "react";
import { SeparatorSection, AnimatedText, AnimationContainer, Label } from "#src/components";
import { Wrapper, Item } from "./LogoSection.styles";

const LogoSection = ({ id, title, label, grid, options }) => {
  return (
    <SeparatorSection id={`${id}`}>
      {label && (
        <AnimationContainer>
          <Label text={label} />
        </AnimationContainer>
      )}
      <h3 className="center mb-10">
        <AnimatedText text={title} />
      </h3>
      <Wrapper {...options}>
        {grid.map((item, i) => (
          <Item {...item?.options}>
            <AnimationContainer delay={i * 0.15} key={i}>
              {item?.image && <img src={item?.image} alt={item?.alt ? item.alt : ""} />}
            </AnimationContainer>
          </Item>
        ))}
      </Wrapper>
    </SeparatorSection>
  );
};

export default LogoSection;
