import React, { useEffect, useId } from "react";
import styled, { css, useTheme } from "styled-components";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Block = styled(motion.span)`
  will-change: transform;
  display: inline-block;
  white-space: pre-wrap;
`;
const Character = styled(motion.span)`
  will-change: transform;
  display: inline-block;
  margin-right: -0.01em;
`;

const Word = styled(motion.span)`
  will-change: transform;
  display: inline-block;
  white-space: pre;
  ${({ $reddot }) =>
    $reddot &&
    css`
      &:nth-last-child(2) {
        margin-right: 0;
      }
      &:last-child {
        margin-right: 0;
        ${Character} {
          &:last-child {
            color: ${({ theme }) => theme.colors.primary};
          }
        }
      }
    `}
`;

const Br = styled.br``;

function AnimatedText({ text, reddot, animateCharacters, delay = 0, block, speed = 1, ...other }) {
  const id = useId();
  const ctrls = useAnimation();
  const { animationProps, intersectionProps } = useTheme();
  const words = text.split(/[ ]+/);
  words.forEach((word, index) => {
    words[index] = index === words.length - 1 ? word : word + " ";
  });
  if (reddot) words.push(".");
  const { ref, inView } = useInView(intersectionProps);

  useEffect(() => {
    if (inView) ctrls.start("visible");
    if (!inView) ctrls.start("hidden");
  }, [ctrls, inView]);

  const wordAnimation = {
    hidden: {},
    visible: {},
  };
  const transition = {
    duration: 1,
    ease: [0.2, 0.65, 0.3, 0.9],
  };
  const characterAnimation = {
    hidden: {
      opacity: 0,
      y: block ? "1em" : `0.25em`,
    },
    visible: {
      opacity: 1,
      y: `0em`,
      transition: block ? { delay: delay, ...transition } : transition,
    },
  };

  if (block)
    return (
      <Block ref={ref} {...animationProps(ctrls, delay)} {...other}>
        {text}
      </Block>
    );
  else
    return (
      <>
        {words.map((word, wi) => {
          if (word.startsWith("\n")) {
            return <Br key={id + wi} />;
          }
          return (
            <Word
              ref={ref}
              $reddot={reddot}
              key={id + wi}
              initial="hidden"
              animate={ctrls}
              variants={wordAnimation}
              transition={{
                delayChildren: delay + (animateCharacters ? (wi * 0.15) / speed : (wi * 0.08) / speed),
                staggerChildren: 0.02,
              }}
              {...other}
            >
              {animateCharacters ? (
                word.split("").map((character, ci) => {
                  return (
                    <Character key={id + ci} variants={characterAnimation}>
                      {character}
                    </Character>
                  );
                })
              ) : (
                <Character key={id + wi} variants={characterAnimation}>
                  {word}
                </Character>
              )}
            </Word>
          );
        })}
      </>
    );
}
export default React.memo(AnimatedText);
