import styled from "styled-components";
import { AnimationContainer } from "../AnimationContainer";

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ columnWidths }) => (!!columnWidths ? columnWidths : "1fr 1.4fr 1fr")};
  grid-template-areas: ${({ areas }) => (!!areas ? areas : '"c1 c2 c3"')};
  gap: ${({ gap }) => (!!gap ? gap : '"10px"')};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    grid-template-columns: 1fr;
    grid-template-areas: ${({ areas }) => (!!areas ? areas : '"c1" "c2" "c3"')};
    width: 100%;
  }
`;
export const Child = styled(AnimationContainer)`
  position: relative;
  grid-area: ${({ area }) => "c" + area};
  ${({ theme }) => theme.flex("column", "center", "stretch")};
  gap: 36px;
  img {
    width: 100%;
    max-width: 460px;
  }
`;
