import React from "react";
import { SeparatorSection, LeftRightLayout, PersonCard, StandardActionButton } from "#src/components";
import { StyledImageBackground, Wrapper, Container } from "./PartnerSection.styles";

const PartnerSection = ({ id, reverse = "true", person, button }) => {
  return (
    <SeparatorSection id={`${id}`}>
      <Wrapper>
        <StyledImageBackground url={person.img} />
        <Container>
          <h4>{person.name}</h4>
          <p>{person.title}</p>
          <StandardActionButton rightIcon={"ARROW_RIGHT"} href={person.linkedin} target="_blank">
            {button}
          </StandardActionButton>
        </Container>
      </Wrapper>
    </SeparatorSection>
  );
};

export default PartnerSection;
