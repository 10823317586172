import React from "react";
import { InfoBoxWrapper } from "./InfoBox.styles";
import { AnimationContainer, Icon, GridLayout } from "#src/components";

const InfoBox = ({ icon, title, text, block, image = 0, ...other }) => {
  return (
    <InfoBoxWrapper {...other} image={image} block={block}>
      <AnimationContainer y={20}>
        <Icon size="32px" icon={icon} />
      </AnimationContainer>
      <h4>{title}</h4>
      <p>{text}</p>
    </InfoBoxWrapper>
  );
};

export default InfoBox;

export const InfoBoxGrid = ({ boxes, columns = 3, ...other }) => {
  return (
    <GridLayout columns={columns} {...other}>
      {boxes.map((box, i) => (
        <InfoBox key={i} {...box} delay={(i % columns) * 0.15} />
      ))}
    </GridLayout>
  );
};
