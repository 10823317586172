import styled from "styled-components";
import { motion } from "framer-motion";

export const ModalContainer = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  z-index: 9998;
  background-color: #003049;
  background: linear-gradient(255.93deg, #000b11dd -17.89%, #000e15dd 47.83%);
  backdrop-filter: blur(60px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 32px;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    padding: 16px;
  }
  overflow-y: auto;
  &:after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 1;
    top: 25px;
    right: 25px;
    cursor: pointer;
    background-image: url("/images/icons/x.svg");
  }
`;
export const ModalWindow = styled(motion.div)`
  height: auto;
  width: 100%;
  min-height: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
  display: flex;
  justify-content: center;
  align-items: center;
`;

