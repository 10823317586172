import React from "react";
import { StyledVideo } from "./VideoPlayer.styles";

const VideoPlayer = ({
  url,
  options = { playsInline: true, autoPlay: true, muted: false },
  ...other
}) => {
  return (
    <StyledVideo
      playsInline
      url={url}
      playing={true}
      controls={true}
      height="auto"
      width="100%"
      config={{
        file: { attributes: { ...options } },
      }}
      {...other}
    />
  );
};

export default VideoPlayer;
