import React from "react";
import { StandardActionButton } from "../../Button";
import { Icon, Brand } from "#src/components";
import { SubMenu } from "../SubMenu";
import { ROUTES } from "#src/common/constants";
import { HeaderLink, NavigationMenu } from "../Navbar.styles";
import { useNavigate } from "react-router";

export default function MenuDesktop({ items, ...other }) {
  const navigate = useNavigate();
  const handleClick = (e, route) => {
    e.preventDefault();
    navigate(route);
  };
  return (
    <NavigationMenu {...other}>
      <ul>
        <li>
          <Brand />
        </li>
        {items.map((item) => (
          <li key={item.path}>
            {!!item?.subRoutes?.length ? (
              <SubMenu data={item} />
            ) : (
              <HeaderLink to={item.path} className={({ isActive }) => (isActive ? "active" : undefined)}>
                {item.title}
              </HeaderLink>
            )}
          </li>
        ))}
        <li></li>
        <li id="menuButton">
          <StandardActionButton
            onClick={(e) => {
              handleClick(e, ROUTES.DEMO.path);
            }}
            leftIcon="CALENDAR"
          >
            Book a demo
          </StandardActionButton>
        </li>
      </ul>
    </NavigationMenu>
  );
}
