import React from "react";
import { SeparatorSection, LeftRightLayout, AnimatedText,  } from "#src/components";
import { StyledLabel, Feature, FeatureText, StyledGridLayout } from "./GlobeSection.styles";
import { BgIcon } from "../BgIcon";
import { StyledLogos } from "../Hero/Hero.styles";

const GlobeSection = ({ label, title, text, list, img, logos }) => {
  return (
    <SeparatorSection>
      <LeftRightLayout
        reverse
        left={
          <>
            <StyledLabel text={label} />
            <h3 className="desktop:pr-10">
              <AnimatedText text={title} />
            </h3>
            <p>
              <AnimatedText text={text} />
            </p>
          </>
        }
        leftProps={{ disableAnimation: true }}
        right={<img src={img} alt={title} width="554" height="431" />}
      />
      <StyledGridLayout columns={4}>
        {list.map((item, i) => (
          <Feature key={i} delay={i * 0.18}>
            <BgIcon icon={item.icon} />
            <FeatureText>
              <AnimatedText text={item.text} />
            </FeatureText>
          </Feature>
        ))}
      </StyledGridLayout>
      <StyledLogos logos={logos} $titleAlign="center" height={26} gap={26} />
    </SeparatorSection>
  );
};

export default GlobeSection;
