import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { rgba, mix } from "#src/Helpers";

const headerLinkStyles = css`
  text-decoration: none;
  transition: color 0.4s ${({ theme }) => theme.ease};
  color: ${({ isScrolled, theme }) =>
    isScrolled ? rgba(theme.colors.secondary, 0.4) : rgba(theme.colors.secondary, 0.6)};
  font-size: 16px;
  line-height: 19px;
  transition: 0.3s ease-in-out;

  &.active {
    color: ${({ theme }) => mix(theme.colors.title, theme.colors.secondary, 0.7)};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.title};
  }
`;

export const StyledNavbar = styled.nav`
  padding: 55px 32px;
  height: 60px;
  width: 100vw;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  position: fixed;
  ${({ theme }) => theme.flex()};
  &:before {
    content: "";
    pointer-events: none;
    width: 100%;
    height: 128px;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(1, 20, 32, 1) 0%,
      rgba(2, 23, 36, 0.8) 33%,
      rgba(2, 23, 36, 0.15) 70%,
      rgba(2, 23, 36, 0) 90%
    );
    z-index: -1;
    transition: 0.6s ${({ theme }) => theme.ease};
    transform-origin: top;
    transition-property: opacity, transform;
    opacity: ${({ isScrolled }) => (isScrolled ? 1 : 0)};
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    height: auto;
    padding: 32px;
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    padding: 20px;
  }
  @media (min-width: ${({ theme }) => theme.desktop}) {
    &:hover {
      &:before {
        opacity: 1;
        transform: scaleY(2);
      }
    }
  }
`;

export const Container = styled.div`
  ${({ theme }) => theme.flex()};
  max-width: ${({ theme }) => theme.maxWidth};
  gap: 32px;
  width: 100%;
`;

export const NavigationMenu = styled.div`
  width: 100%;
  &.expanded ul {
    padding-left: 0;
  }

  #menuButton {
    margin-left: auto;
    @media (max-width: ${({ theme }) => theme.tablet}) {
      margin-right: 0;
    }
  }

  ul {
    display: flex;
    align-items: center;
    padding: 0;
    gap: 32px;
    @media (max-width: ${({ theme }) => theme.tablet}) {
      padding-left: 2rem;
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
      padding-left: 0;
    }
  }
  ul li {
    list-style-type: none;
  }

  @media (max-width: ${({ theme }) => theme.tablet}) {
    ul {
      /* display: none; */
      position: absolute;
      top: 60px;
      left: 0;
      flex-direction: column;
      width: 100%;
      /* height: calc(100vh - 77px); */
      display: flex;
      justify-content: center;
      ${({ theme }) => theme.heroBg()};
      z-index: 1;
      margin-top: -60px;
      padding-top: 30px;
      height: 100vh;
      transform: translateY(100%);
      opacity: 0;
      /* background-color: white; */
      transition: transform 0.3s ease 0.2s, opacity 0.3s ${({ theme }) => theme.ease};
    }
    &.expanded ul {
      transition: transform 0.3s ease, opacity 0.3s ease 0.2s;
      transform: translateY(0);
      opacity: 1;
    }
    li {
      text-align: center;
      margin: 0;
    }
    #menuButton {
      margin-left: 0;
      a {
        display: flex;
        justify-content: center;
      }
    }
  }
`;

export const HeaderLink = styled(NavLink)`
  ${headerLinkStyles};
`;

export const FakeHeaderLink = styled.span`
  cursor: pointer;
  ${headerLinkStyles};
`;


export const Intersection = styled.div`
    position: fixed;
    width: 100vw;
    height: 1px;
    background: green;
    top: 50vh;
    left: 0;
    z-index: 100000;
`