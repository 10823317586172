import React from "react";
import {
  Container,
  CommonHero,
  SeparatorSection,
  LeftRightLayout,
  AnimatedText,
  AnimationContainer,
  InfoBoxGrid,
  PreFooter,
  ShareMeta,
  ImageBackground,
  ThreeColumnLayout,
  FeatureList,
  LearnMoreButton,
  Label,
} from "#src/components";
import { corporateStructureVerificationData as data } from "#src/data";
import { useMedia } from "#src/hooks";
// import {} from "./CorporateStructureVerification.styles"

const CorporateStructureVerification = () => {
  const { hero, sections, navColumns } = data;
  const { isMobile } = useMedia();

  return (
    <Container>
      <ShareMeta title={hero.title} description={hero.text} type="website" />
      <CommonHero imageProps={{ screen: 1 }} {...hero} />
      <SeparatorSection>
        <ThreeColumnLayout
          areas={isMobile ? '"c1" "c3" "c2" ' : undefined}
          columns={[
            <FeatureList
              alignRight
              alt
              features={sections.slice(0, 4).map((section, i) => {
                return { title: section.label, url: `#sec_${i}` };
              })}
            />,
            <ImageBackground {...navColumns.image} />,
            <FeatureList
              alt
              features={sections.slice(4, 7).map((section, i) => {
                return { title: section.label, url: `#sec_${i + 4}` };
              })}
            />,
          ]}
        />
      </SeparatorSection>
      {sections.map((section, i) => {
        return !section?.features ? (
          <SeparatorSection key={i} id={`sec_${i}`}>
            <LeftRightLayout
              imgHeight="560px"
              reverse={!(i % 2)}
              left={
                <>
                  <AnimationContainer>
                    <Label text={section.label} />
                  </AnimationContainer>
                  <h3 className="desktop:pr-10">
                    <AnimatedText text={section.title} />
                  </h3>
                  <p>{section.text}</p>
                  {section?.link?.url && <LearnMoreButton href={section.link.url} text={section.link.text} />}
                </>
              }
              right={<ImageBackground url={section.image} />}
            />
          </SeparatorSection>
        ) : (
          <SeparatorSection key={i} id={`sec_${i}`}>
            <h3 className="center mb-10">
              <AnimatedText text={section.title} />
            </h3>
            <InfoBoxGrid boxes={section.features} />
          </SeparatorSection>
        );
      })}
      <PreFooter />
    </Container>
  );
};

export default CorporateStructureVerification;
