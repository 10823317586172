import React, { lazy, Suspense } from "react";
import { LoadingScreen } from "#src/components";

const VideoPlayer = lazy(() => import("#src/components/VideoPlayer/VideoPlayer"));

const ModalVideo = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <VideoPlayer url="./videos/binderr_long.mp4" />
    </Suspense>
  );
};

export default React.memo(ModalVideo);
