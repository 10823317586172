import styled, { css } from "styled-components";
import { rgba } from "#src/Helpers";
import { NavLink } from "react-router-dom";
import { Icon } from "../../Icon";
import { motion } from "framer-motion";

export const SubMenuWrapper = styled(motion.div)`
  position: relative;
  width: auto;
  z-index: 1;
  border: 1px solid ${({ theme }) => rgba(theme.colors.primary, 0.2)};
  border-radius: 24px;
  background: rgba(26, 36, 48, 0.55);
  box-shadow: 4px 4px 50px 0px rgba(0, 0, 0, 0.25);
  padding: 24px;
  align-items: flex-start;
  grid-column-gap: 24px;
  grid-row-gap: 8px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 22px;
  backdrop-filter: blur(32px);
`;

export const SubMenuItem = styled(NavLink)`
  display: grid;
  width: 240px;
  grid-template-areas: "icon title" "icon description";
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  grid-template-columns: 16px 1fr;
  padding: 12px;
  border-radius: 12px;
  transition: background-color 0.2s ${({ theme }) => theme.ease};
  text-decoration: none;
  &:hover {
    background-color: rgba(103, 116, 244, 0.1);
  }
  &.active {
    background-color: rgba(103, 116, 244, 0.06);
  }
  svg {
    grid-area: icon;
    color: ${({ theme }) => theme.colors.primary};
    margin-top: 4px;
  }
  h4 {
    grid-area: title;
    font-size: 16px;
    line-height: 23px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.title};
  }
  p {
    grid-area: description;
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;


export const MobileSubMenuItem = styled(SubMenuItem)`
  width: 100%;
`
export const MobileSubMenuWrapper = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`
export const MobileSubMenuContainer = styled.div`
  padding-bottom: 32px;
`

export const Chevron = styled(Icon)`
  transition: transform 0.3s ${({ theme }) => theme.ease};
  ${({ expanded }) =>
    expanded &&
    css`
      transform: rotate(180deg) translateY(-3px);
    `}
`;