import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ROUTES } from "./common/constants";
import { Navbar, LoadingScreen, ScrollToAnchor } from "./components";
import { AnimatePresence, motion } from "framer-motion";
import { ScrollToTop } from "./Helpers";
import styled from "styled-components";
import { RiskAssessment } from "./pages/Product/pages";

const Home = lazy(() => import("./pages/Home/Home"));
const About = lazy(() => import("./pages/About/About"));
const Security = lazy(() => import("./pages/Security/Security"));
const ServicesAgreement = lazy(() => import("./pages/ServicesAgreement/ServicesAgreement"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const NotFound = lazy(() => import("./pages/NotFound/NotFound"));
const News = lazy(() => import("./pages/News/News"));
const Article = lazy(() => import("./pages/News/Article"));
const Demo = lazy(() => import("./pages/Demo/Demo"));
const Partnership = lazy(() => import("./pages/Partnership/Partnership"));
const KYCAML = lazy(() => import("./pages/Product/pages/KYCAML/KYCAML"));
const Corporate = lazy(() => import("./pages/Product/pages/Corporate/Corporate"));
const EntityManagement = lazy(() => import("./pages/Product/pages/EntityManagement/EntityManagement"));
const Questionnaires = lazy(() => import("./pages/Product/pages/Questionnaires/Questionnaires"));
const TaskManager = lazy(() => import("./pages/Product/pages/TaskManager/TaskManager"));
const Reporting = lazy(() => import("./pages/Product/pages/Reporting/Reporting"));
const CorporateStructureVerification = lazy(() =>
  import("./pages/Product/pages/CorporateStructureVerification/CorporateStructureVerification")
);
const CRF = lazy(() => import("./pages/CRF/CRF"));
const Thankyou = lazy(() => import("./pages/Thankyou/Thankyou"));
const Footer = lazy(() => import("./components/Footer/Footer"));

export default function AppRouter({ loading }) {
  const mapRoutes = {
    HOME: { ...ROUTES.HOME, element: <Home /> },
    SECURITY: { ...ROUTES.SECURITY, element: <Security /> },
    ABOUT: { ...ROUTES.ABOUT, element: <About /> },
    SERVICES: { ...ROUTES.SERVICES, element: <ServicesAgreement /> },
    PRIVACY: { ...ROUTES.PRIVACY, element: <PrivacyPolicy /> },
    NEWS: { ...ROUTES.NEWS, element: <News /> },
    DEMO: { ...ROUTES.DEMO, element: <Demo /> },
    CRF: { ...ROUTES.CRF, element: <CRF /> },
    PARTNERSHIP: { ...ROUTES.PARTNERSHIP, element: <Partnership /> },
    THANKYOU: { ...ROUTES.THANKYOU, element: <Thankyou /> },
    KYCAML: {
      ...ROUTES.KYCAML,
      element: <KYCAML />,
      icon: "PERSON_TICK",
      description: "Streamline your compliance with advanced automation",
    },
    CORPORATE: {
      ...ROUTES.CORPORATE,
      element: <Corporate />,
      icon: "WORKFLOW",
      description: "Effortless corporate submissions in clicks",
    },
    ENTITYMANAGEMENT: {
      ...ROUTES.ENTITYMANAGEMENT,
      element: <EntityManagement />,
      icon: "LIST",
      description: "One hub for all your client data",
    },
    QUESTIONNAIRES: {
      ...ROUTES.QUESTIONNAIRES,
      element: <Questionnaires />,
      icon: "PERSON",
      description: "Obtain data from your clients seamlessly",
    },
    TASKMANAGER: {
      ...ROUTES.TASKMANAGER,
      element: <TaskManager />,
      icon: "SUITCASE",
      description: "Master task management with ease",
    },
    REPORTING: {
      ...ROUTES.REPORTING,
      element: <Reporting />,
      icon: "PRESENTATION",
      description: "Simplify compliance reporting",
    },
    CORPORATESTRUCTUREVERIFICATION: {
      ...ROUTES.CORPORATESTRUCTUREVERIFICATION,
      element: <CorporateStructureVerification />,
      icon: "NETWORK",
      description: "Verify corporate structures in one click",
    },
    RISKASSESSMENT: {
      ...ROUTES.RISKASSESSMENT,
      element: <RiskAssessment />,
      icon: "METER",
      description: "Gain a full picture of your risk landscape",
    },
  };
  const productSubRoutes = [
    mapRoutes.KYCAML,
    // mapRoutes.TASKMANAGER,
    mapRoutes.QUESTIONNAIRES,
    mapRoutes.ENTITYMANAGEMENT,
    mapRoutes.CORPORATE,
    // mapRoutes.REPORTING,
    mapRoutes.CORPORATESTRUCTUREVERIFICATION,
    mapRoutes.RISKASSESSMENT,
  ];
  const menuItems = [
    ROUTES.HOME,
    { ...ROUTES.PRODUCT, subRoutes: productSubRoutes },
    ROUTES.SECURITY,
    ROUTES.PARTNERSHIP,
    ROUTES.ABOUT,
    ROUTES.NEWS,
  ];

  const productRoutes = [...productSubRoutes, ROUTES.SECURITY];
  const companyRoutes = [ROUTES.HOME, ROUTES.ABOUT, ROUTES.NEWS, ROUTES.DEMO, ROUTES.SERVICES, ROUTES.PRIVACY];

  const AnimatedDiv = styled(motion.div)`
    transform: translate3d(0, 0, 0);
  `;
  const Anim = ({ children, index }) => (
    <AnimatedDiv
      key={index}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      {children}
    </AnimatedDiv>
  );
  return (
    <Router>
      <Navbar items={menuItems} />
      <ScrollToTop />
      <ScrollToAnchor />
      <AnimatePresence>
        <Routes>
          <Route
            path="/*"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <NotFound />
              </Suspense>
            }
          />
          <Route
            exact
            path={ROUTES.POST.path}
            element={
              <Suspense fallback={<LoadingScreen />}>
                <Article />
              </Suspense>
            }
          />
          {Object.values(mapRoutes).map((route, index) => (
            <Route
              exact
              key={index}
              path={route.path}
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <Anim index={index}>{route.element}</Anim>
                </Suspense>
              }
            />
          ))}
        </Routes>
      </AnimatePresence>
      <LoadingScreen loading={loading} />
      {!loading && (
        <Suspense fallback={<></>}>
          <Footer menu={{ productRoutes, companyRoutes }} />
        </Suspense>
      )}
    </Router>
  );
}
