import React from "react";
import { ROUTES } from "#src/common/constants";
import { LogoLink } from "./Brand.styles";

const Brand = () => {
  return (
    <LogoLink to={ROUTES.HOME.path} onClick={() => window.scrollTo(0, 0)}>
      <img src="/images/logos/logo.svg" alt="binderr logo" width="125" height="39" />
    </LogoLink>
  );
};

export default Brand;
