import React, { useState, useEffect } from "react";
import { FakeMobileHeaderLink } from "../MenuMobile/MenuMobile.styles";
import { MobileSubMenuItem, MobileSubMenuWrapper, MobileSubMenuContainer, Chevron } from "./SubMenu.styles";
import { Icon, AnimationContainer } from "#src/components";
import AnimateHeight from "react-animate-height";
import { AnimatePresence } from "framer-motion";
import { useTheme } from "styled-components";
import { useLocation } from "react-router";

export default function SubMenuMobile({ title, subRoutes }) {
  const location = useLocation();
  const isLinkActive = subRoutes.some((sub) => sub.path === location.pathname);
  const { ease } = useTheme();
  const [open, setOpen] = useState(false);
  useEffect(() => { 
    setOpen(isLinkActive);
  }, [isLinkActive]);
  return (
    <MobileSubMenuWrapper>
      <FakeMobileHeaderLink
        onClick={() => {
          setOpen(!open);
        }}
        className={open ? "active" : undefined}
      >
        {title} <Chevron icon="CHEVRON_DOWN" expanded={open} />
      </FakeMobileHeaderLink>
      <AnimateHeight duration={400} height={open ? "auto" : 0} easing={ease}>
        <MobileSubMenuContainer>
          <AnimatePresence>
            {open &&
              subRoutes.map((sub, i) => (
                <AnimationContainer key={sub.path} delay={i * 0.075}>
                  <MobileSubMenuItem to={sub.path}>
                    <Icon icon={sub.icon} />
                    <h4>{sub.title}</h4>
                    <p>{sub.description}</p>
                  </MobileSubMenuItem>
                </AnimationContainer>
              ))}
          </AnimatePresence>
        </MobileSubMenuContainer>
      </AnimateHeight>
    </MobileSubMenuWrapper>
  );
}
