import React from "react";
import { CTAWrapper } from "./CTA.styles";
import { BookADemoButton } from "../Button";

function CTA({ data }) {
  const { text, btn } = data;
  return (
    <CTAWrapper>
      <h4>{text}</h4>
      <BookADemoButton text={btn?.text} />
    </CTAWrapper>
  );
}
export default React.memo(CTA);
