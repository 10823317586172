import React, { forwardRef } from "react";
import { Wrapper, Container, Left, Right } from "./LeftRightLayout.styles";
import { useMedia } from "#src/hooks";

export const LeftRightLayout = React.memo(
  forwardRef(
    (
      {
        left,
        right,
        reverse,
        leftProps = { y: 0, x: -30 },
        rightProps = { y: 0, x: 30, delay: 0.2 },
        className,
        leftClassName,
        rightClassName,
        ...other
      },
      ref
    ) => {
      const { isTablet } = useMedia();
      const lp = isTablet ? undefined : reverse ? rightProps : leftProps;
      const rp = isTablet ? undefined : reverse ? leftProps : rightProps;
      return (
        <Wrapper className={className} ref={ref}>
          <Container {...other} reverse={reverse ? 1 : 0}>
            <Left className={leftClassName} {...lp} reverse={reverse ? 1 : 0}>
              {left}
            </Left>
            <Right className={rightClassName} {...rp} reverse={reverse ? 1 : 0}>
              {right}
            </Right>
          </Container>
        </Wrapper>
      );
    }
  )
);
