import React, { useContext } from "react";
import { Card, CardDrawer, CardModal, CardModalContent, Details } from "./PersonCard.styles.js";
import { AnimatedText, AnimationContainer, Icon } from "../";
import { DispatchContext } from "../../context";

const PersonCard = ({ details, className, other }) => {
  const { name, title, description, linkedin, img } = details;
  const dispatch = useContext(DispatchContext);
  return (
    <Card
      className={className}
      {...other}
      onClick={() => {
        dispatch({ type: "setModalOpen", modalOpen: true });
        dispatch({
          type: "setContainerStyle",
          containerStyle: { background: "#000b1189" },
        });
        dispatch({
          type: "setModalStyle",
          modalStyle: { width: "auto" },
        });
        dispatch({
          type: "setModalChildren",
          modalChildren: (
            <CardModal>
              <img src={img ? img : "/images/team/nopic.png"} alt={`${name}, ${title}`} />
              <CardModalContent>
                <h2>
                  <AnimatedText text={name} />
                </h2>
                <label>
                  <AnimatedText text={title} delay={0.3} />
                </label>
                <p>
                  <AnimatedText text={description} block delay={0.5} />
                </p>
                {linkedin && (
                  <AnimationContainer delay={0.7}>
                    <a target="_blank" href={linkedin} rel="noreferrer">
                      Connect on LinkedIn
                    </a>
                  </AnimationContainer>
                )}
              </CardModalContent>
            </CardModal>
          ),
        });
      }}
    >
      <img src={img ? img : "/images/team/nopic.png"} alt={`${name}, ${title}`} />
      <CardDrawer>
        <h3>{name}</h3>
        <label>{title}</label>
        <Details>Show details <Icon icon="ARROW_BOTTOM" /></Details>
      </CardDrawer>
    </Card>
  );
};

export default React.memo(PersonCard);
