import styled from "styled-components";
import { AnimationContainer } from "#src/components";
import { NavLink } from "react-router-dom";

export const Wrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.tablet}) {
    display: flex;
    justify-content: center;
    z-index: 999;
  }
  position: relative;
  width: 100%;
`;
export const Container = styled.div`
  display: flex;
  position: relative;
  @media (min-width: ${({ theme }) => theme.tablet}) {
    justify-content: center;
    translate: 0 -150px;
  }
`;
export const Content = styled(AnimationContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 16px 112px 16px;
  flex: 1;
  @media (min-width: ${({ theme }) => theme.mobile}) {
    padding: 24px 32px 112px 32px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 42px 64px;
    border-radius: 24px;
    border: 1px solid #304b69;
    background: rgba(26, 36, 48, 0.21);
    backdrop-filter: blur(32px);
  }
  gap: 16px;
  opacity: 1;
  &:after {
    content: "";
    height: 150px;
    height: 300px;
    width: 800px;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("/images/bg_struct_0.png");
    opacity: 0.5;
    @media (min-width: ${({ theme }) => theme.tablet}) {
      background-position: center top;
      top: 100%;
    }
    @media (max-width: ${({ theme }) => theme.tablet}) {
      background-position: center bottom;
      bottom: -99px;
      left: -410px;
    }
  }
  @media (min-width: ${({ theme }) => theme.tablet}) {
    h4 {
      font-size: 27.525px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }
  p {
    font-style: normal;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;
export const StyledNavLink = styled(NavLink)`
    font-style: normal;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.secondary};
`;