import React, { forwardRef } from "react";
import Hero from "./Hero";
import {
  StyledFullPageHero,
  StyledLabel,
  StyledVideoPlayer,
  IllustrationContainer,
  IllustrationWrapper,
  StyledLogos,
} from "./Hero.styles";
import {
  BookADemoButton,
  AnimatedText,
  AnimationContainer,
  Label,
  ActionsContainer,
  RotatingCard,
} from "#src/components";
import { useMedia } from "#src/hooks";
import { generateSrcset } from "#src/Helpers";
import Wireframe from "./Wireframe";
// import EdgeShine from "./EdgeShine";

const CommonHero = forwardRef(
  (
    {
      label,
      title,
      text,
      subtitle,
      btn = <BookADemoButton />,
      btn2,
      image,
      video,
      imageProps,
      imgProps,
      center,
      logos,
      wireframeAnim,
      bottomLayer,
      ...other
    },
    ref
  ) => {
    const { isMobile } = useMedia();
    const laptopAnim = {
      hidden: {
        opacity: 0,
        rotateX: 45,
      },
      visible: {
        opacity: 1,
        rotateX: 0,
        transition: {
          type: "spring",
          // duration: 1.4,
          // delay: 1.2,
          damping: 60,
        },
      },
    };
    return center && !isMobile ? (
      <StyledFullPageHero {...other}>
        {!!label && (
          <AnimationContainer y={20} speed={3} delay={0.1}>
            <StyledLabel text={label} />
          </AnimationContainer>
        )}
        <h1>
          <AnimatedText text={title} />
        </h1>
        {subtitle && (
          <h4>
            <AnimatedText text={subtitle} />
          </h4>
        )}
        <p className="mb-10">
          <AnimatedText text={text} delay={0.2} />
        </p>
        <ActionsContainer>
          <AnimationContainer delay={0.1}>{!!btn && btn}</AnimationContainer>
          <AnimationContainer delay={0.2}>{!!btn2 && btn2}</AnimationContainer>
        </ActionsContainer>
        {!!image &&
          (!!wireframeAnim ? (
            <AnimationContainer playOnMount customAnimation={laptopAnim}>
              <IllustrationWrapper>
                <RotatingCard>
                  <IllustrationContainer disableAnimation center={center} bg={image} {...imageProps}>
                    <Wireframe />
                    <img
                      alt={title}
                      src={image}
                      {...imgProps}
                      srcSet={imgProps?.adaptive ? generateSrcset(image) : null}
                    />
                    {/* <EdgeShine /> */}
                  </IllustrationContainer>
                </RotatingCard>
              </IllustrationWrapper>
            </AnimationContainer>
          ) : (
            <AnimationContainer playOnMount delay={0.3}>
              <IllustrationWrapper>
                <IllustrationContainer disableAnimation center={center} bg={image} {...imageProps}>
                  <img
                    alt={title}
                    src={image}
                    {...imgProps}
                    srcSet={imgProps?.adaptive ? generateSrcset(image) : null}
                  />
                </IllustrationContainer>
              </IllustrationWrapper>
            </AnimationContainer>
          ))}

        {!!video && (
          <IllustrationWrapper>
            <IllustrationContainer y={0} x={30} bg={image} center={center} {...imageProps}>
              <StyledVideoPlayer url={video} options={{ playsInline: true, autoPlay: true, muted: true, loop: true }} />
            </IllustrationContainer>
          </IllustrationWrapper>
        )}
      </StyledFullPageHero>
    ) : (
      <Hero
        ref={ref}
        leftProps={{ disableAnimation: true }}
        left={
          <>
            {!!label && (
              <AnimationContainer playOnMount y={20} delay={0.1}>
                <Label text={label} />
              </AnimationContainer>
            )}
            <h1>
              <AnimatedText text={title} />
            </h1>
            <p>
              {subtitle && (
                <>
                  <i>
                    <AnimatedText text={subtitle} />
                  </i>
                  <br />
                  <br />
                </>
              )}
              <AnimatedText text={text} speed={3} delay={0.4} />
            </p>
            {!!logos && isMobile && <StyledLogos playOnMount logos={logos} delay={0.3} />}
            <ActionsContainer>
              {!!btn && (
                <AnimationContainer playOnMount delay={0.1}>
                  {btn}
                </AnimationContainer>
              )}
              {!!btn2 && (
                <AnimationContainer playOnMount delay={0.2}>
                  {btn2}
                </AnimationContainer>
              )}
            </ActionsContainer>
            {!!logos && !isMobile && <StyledLogos playOnMount logos={logos} delay={0.3} />}
          </>
        }
        rightProps={{ disableAnimation: true }}
        right={
          <>
            {!!image && (
              <IllustrationContainer y={0} x={30} bg={image} {...imageProps}>
                <img
                  alt={title}
                  src={image}
                  {...imgProps}
                  srcSet={!!imgProps ? generateSrcset(image, [400, 600, 800, 1000, 1200]) : null}
                />
              </IllustrationContainer>
            )}
            {!!video && (
              <IllustrationContainer y={0} x={30} video={video ? 1 : 0} {...imageProps}>
                <StyledVideoPlayer
                  url={video}
                  options={{ playsInline: true, autoPlay: true, muted: true, loop: true, controls: false }}
                />
              </IllustrationContainer>
            )}
          </>
        }
        {...other}
      />
    );
  }
);

export default CommonHero;
