import React from "react";
import { Wrapper, Child } from "./ThreeColumnLayout.styles";

const ThreeColumnLayout = ({ columns, ...other }) => {
  return (
    <Wrapper {...other}>
      {columns.map((column, index) => {
        return (
          <Child key={index} area={index + 1} delay={index * 0.15}>
            {column}
          </Child>
        );
      })}
    </Wrapper>
  );
};

export default ThreeColumnLayout;
