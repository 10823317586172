import React from "react";
import { LogosWrapper, LogosContainer, LogosRow, LogosTitle } from "./Logos.styles";
import { AnimationContainer } from "../AnimationContainer";
import AnimatedText from "../AnimatedText";

const LogosElem = ({ title, imgs, widths, height = 20, opacity, playOnMount, ...other }) => {
  return (
    <LogosContainer {...other}>
      {!!title && (
        <LogosTitle>
          <AnimatedText text={title} />
        </LogosTitle>
      )}
      <LogosRow height={height}>
        {!!imgs.length &&
          imgs.map((img, ii) => (
            <AnimationContainer playOnMount={playOnMount} key={ii} delay={0.15 + 0.15 * ii}>
              <img
                className={`opacity-${opacity}`}
                src={img}
                alt={img}
                height={height}
                width={widths?.length ? widths[ii] : 100}
              />
            </AnimationContainer>
          ))}
      </LogosRow>
    </LogosContainer>
  );
};

const Logos = ({ logos, delay = 0, opacity = 100, className, ...other }) => {
  return (
    <LogosWrapper className={className}>
      {!!logos && Array.isArray(logos) ? (
        logos.map((group, gi) => (
          <LogosElem opacity={opacity} {...group} key={gi} delay={delay + gi * 0.15} {...other} />
        ))
      ) : (
        <LogosElem opacity={opacity} {...logos} delay={delay} {...other} />
      )}
    </LogosWrapper>
  );
};

export default React.memo(Logos);
