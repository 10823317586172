import React from "react";
import { StyledHamburger } from "./MenuMobile.styles";

export const Hamburger = ({ ...other }) => {
  return (
    <StyledHamburger {...other}>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="hamburger">
          <rect y="15" x="8" width="24" height="2" rx="0.5" fill="white" fillOpacity="0.6" />
          <rect y="23" x="8" width="24" height="2" rx="0.5" fill="white" fillOpacity="0.6" />
        </g>
      </svg>
    </StyledHamburger>
  );
};
