import React, { useState, useEffect } from "react";
import { Wrapper, StyledMenu, MenuContainer, MobileHeaderLink } from "./MenuMobile.styles";
import { Hamburger } from "./Hamburger";
import { SubMenuMobile } from "../SubMenu";
import { BookADemoButton, AnimationContainer } from "#src/components";
import { useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useScrollBlock } from "#src/hooks";

const MenuMobile = ({ items, ...other }) => {
  let location = useLocation();
  const [blockScroll, allowScroll] = useScrollBlock();
  const [open, setOpen] = useState(true);
  const animProps = {
    initial: { opacity: 0, y: 0 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0 },
    transition: { duration: 0.2 },
  };

  useEffect(() => {
    setOpen(false);
  }, [location]);

  useEffect(() => {
    open ? blockScroll() : allowScroll();
  }, [open, blockScroll, allowScroll]);
  return (
    <>
      <Wrapper {...other}>
        {/* <Brand /> */}
        <Hamburger
          expanded={open}
          onClick={() => {
            setOpen(!open);
          }}
          aria-label="Toggle menu"
        />
      </Wrapper>
      <AnimatePresence>
        {open && (
          <StyledMenu {...animProps}>
            <MenuContainer>
              <ul>
                {items.map((item, i) => (
                  <li key={item.path}>
                    <AnimationContainer delay={i * 0.075}>
                      {!!item?.subRoutes?.length ? (
                        <SubMenuMobile {...item} />
                      ) : (
                        <MobileHeaderLink
                          to={item.path}
                          className={({ isActive }) => (isActive ? "active" : undefined)}
                        >
                          {item.title}
                        </MobileHeaderLink>
                      )}
                    </AnimationContainer>
                  </li>
                ))}
              </ul>
              <BookADemoButton />
            </MenuContainer>
          </StyledMenu>
        )}
      </AnimatePresence>
    </>
  );
};

export default MenuMobile;
