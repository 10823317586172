import styled from "styled-components";
import { AnimationContainer } from "../AnimationContainer";
import { motion } from "framer-motion";
import { LogosContainer } from "../Logos/";
import { Copy } from "../";

export const Card = styled(AnimationContainer)`
  ${({ theme }) => theme.gradientStroke("32px")};
  background-image: url(/images/elements/containercardbg.webp);
  background-size: cover;
  background-position: right bottom;
  background-repeat: no-repeat;
  width: 100%;
  height: 575px;
  display: grid;
  gap: 72px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "a b";
  overflow: hidden;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    grid-template-columns: 1fr 2fr;
    height: auto;
    padding: 64px;
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    display: block;
    padding: 0;
    padding-top: 144px;
  }
  ${LogosContainer} {
    align-items: flex-start;
  }
`;
export const CardCopy = styled(Copy)`
  z-index: 1;
  padding-right: 64px;

  @media (min-width: ${({ theme }) => theme.desktop}) {
    h3 {
      font-size: 36px;
      line-height: 120%;
    }
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    padding: 0;
  }
`;

export const PerspectiveImage = styled(motion.div)`
  z-index: 0;
  background-size: contain;
  background-position: center;
  background-image: url(${({ src }) => src});
  position: absolute;
  aspect-ratio: 444 / 364;
  transform-origin: center;
  transform: translateZ(0);
  backface-visibility: hidden;
  transform: translateY(-80px) rotateX(45deg) rotateZ(-45deg) scale(1.4);
  height: 100%;
  mask-image: radial-gradient(ellipse 65% 65% at 25% 50%, black 0%, transparent 70%);
  pointer-events: none;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    height: 60%;
    top: -10%;
  }
  @media (max-width: ${({ theme }) => theme.phone}) {
    height: auto;
    width: 105%;
    top: 0;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
`;
