import React, { useEffect } from "react";
import { ItemWrapper, StyledBgIcon, ItemContent, Ul, Li, LiIcon } from "./Timeline.styles";
import { AnimatedText, StandardActionButton } from "#src/components";
import { useCenterDistance } from "#src/hooks";
import { transposeNumber } from "#src/Helpers";
import { useMedia } from "#src/hooks";

const Item = ({ title, icon, list, button, image, active, index, setActiveCallback, ...other }) => {
  const { ref, centerDistanceRatio } = useCenterDistance();
  useEffect(() => {
    if (typeof setActiveCallback === "function") {
      setActiveCallback(centerDistanceRatio, index);
    }
  }, [centerDistanceRatio, index, setActiveCallback]);
  const { isTablet } = useMedia();
  return (
    <ItemWrapper active={centerDistanceRatio < 0.2} {...other} ref={ref}>
      <StyledBgIcon
        icon={icon}
        active={centerDistanceRatio < 0.4}
        styles={
          !isTablet && [
            { transform: `scale(${transposeNumber(centerDistanceRatio, 1, 0.4, 0.95, 1)})` },
            { opacity: transposeNumber(centerDistanceRatio, 1, 0.4, 0, 1) },
            { opacity: transposeNumber(centerDistanceRatio, 1, 0.4, 0.2, 1) },
          ]
        }
      />
      <ItemContent
        active={centerDistanceRatio < 0.2}
        style={{ opacity: isTablet ? 1 : transposeNumber(centerDistanceRatio, 1, 0.4, 0.1, 1) }}
      >
        <h4>
          <AnimatedText text={title} />
        </h4>
        <Ul>
          {list.map((item, i) => (
            <Li key={i} delay={i * 0.06}>
              <LiIcon src={item.icon} alt={`icon ${i}`} width="19" height="19" />
              {item.text}
            </Li>
          ))}
          <Li delay={list.length * 0.06}>
            <StandardActionButton
              style={{ opacity: isTablet ? 1 : transposeNumber(centerDistanceRatio, 1, 0.4, 0.3, 1) }}
              to={button.url}
            >
              {button.text}
            </StandardActionButton>
          </Li>
        </Ul>
      </ItemContent>
    </ItemWrapper>
  );
};

export default Item;
