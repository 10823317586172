import styled from "styled-components";
import { motion } from "framer-motion";

export const BeforeSpan = styled.span``
export const AfterSpan = styled.span``

export const DynamicRow = styled.span`
  overflow: hidden;
  display: inline-flex;
  transition: width 800ms cubic-bezier(.8,0,.2,1);
  white-space: nowrap;
  text-align: left;
  position: relative;
  ${AfterSpan} {
    position: absolute;
    right: 0;
  }
`
export const PhantomRow = styled.span`
  position: absolute;
  height: 0;
  opacity: 0;
  white-space: nowrap;
  max-width: 100%;
`

export const AnimatedSpan = styled(motion.span)`
  color: ${({ color }) => color};
  display: inline-block;
`