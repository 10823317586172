import styled from "styled-components";

export const BlurryBackgroundWrapper = styled.div`
  position: relative;
  z-index: -1;
`;

export const BlobsContainer = styled.div`
  justify-content: center;
  align-items: center;
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;

// Styled component for individual blob
export const Blob = styled.div`
  position: absolute;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transform-origin: center;
  /* border: 1px solid red; */
`;
