import styled, { css } from "styled-components";
import { AnimationContainer } from "#src/components";
import { NavLink } from "react-router-dom";

export const Content = styled.div`
  ${({ theme }) => theme.flex("column", "flex-start", "flex-start")};
`;

export const FeatureWrapper = styled(AnimationContainer)`
  position: relative;
  display: flex;
  text-align: left;
  max-width: 580px;
  gap: 32px;
  grid-area: ${({ area }) => (area ? area : "auto")};
  @media (max-width: ${({ theme }) => theme.tablet}) {
    flex-direction: column;
  }
  p {
    font-size: 18px;
    line-height: 23px;
    color: ${({ theme, sm }) => (sm ? theme.colors.title : theme.colors.secondary)};
    @media (max-width: ${({ theme }) => theme.tablet}) {
      flex-direction: column;
    }
  }
  h4 {
    margin-bottom: 12px;
    ${({ theme }) => theme.flex("row", "flex-start", "center")};
    gap: 12px;
    svg {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  ${({ alt }) =>
    alt &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.dark};
      padding-bottom: 24px;
      h4 {
        margin-bottom: 20px;
      }
    `}
  ${({ sm }) =>
    sm &&
    css`
      &::after {
        ${({ theme }) => theme.pseudoFull};
        left: 1px;
        border-right: 1px solid ${({ theme }) => theme.colors.dark};
      }
      @media (max-width: ${({ theme }) => theme.tablet}) {
        ${Content} {
          flex-direction: row;
          gap: 12px;
        }
        &:after {
          border-right: none;
          border-bottom: 1px solid ${({ theme }) => theme.colors.dark};
        }
      }
    `}
`;

export const FeatureListWrapper = styled.div`
  ${({ theme }) => theme.flex("column", "flex-start", "flex-start")};
  gap: 48px;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    gap: 24px;
    padding-bottom: 24px;
  }

  ${({ alignRight }) =>
    alignRight &&
    css`
      @media (min-width: ${({ theme }) => theme.tablet}) {
        align-items: flex-end;
        h4 {
          text-align: right;
        }
      }
    `}
`;

export const StyledNavLink = styled(NavLink)`
  transition: color 0.2s ease;
  color: ${({ theme }) => theme.colors.secondary};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
