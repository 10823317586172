import React, { useState } from "react";
import { SubMenuWrapper, SubMenuItem } from "./SubMenu.styles";
import { FakeHeaderLink } from "../Navbar.styles";
import { Icon, AnimationContainer, DropdownMenu } from "#src/components";
import { useLocation } from "react-router-dom";

const SubMenu = ({ data }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();
  const isLinkActive = data.subRoutes.some((sub) => sub.path === location.pathname);
  const animProps = {
    initial: { opacity: 0, y: 0 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0 },
    transition: { duration: 0.2 },
  };

  return (
    <DropdownMenu
      disableAnimation
      offsetLeft="32px"
      onChange={(v) => setIsDropdownOpen(v)}
      target={
        <FakeHeaderLink className={isDropdownOpen || isLinkActive ? "active" : undefined}>
          Product <Icon icon="CHEVRON_DOWN" />
        </FakeHeaderLink>
      }
      menu={
        <SubMenuWrapper {...animProps}>
          {data.subRoutes.map((sub, i) => (
            <AnimationContainer key={sub.path} delay={i * 0.075}>
              <SubMenuItem to={sub.path}>
                <Icon icon={sub.icon} />
                <h4>{sub.title}</h4>
                <p>{sub.description}</p>
              </SubMenuItem>
            </AnimationContainer>
          ))}
        </SubMenuWrapper>
      }
    />
  );
};

export default React.memo(SubMenu);
