import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
`;
export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  gap: ${({ gap }) => gap};
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
  @media (max-width: ${({ theme }) => theme.tablet}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${({ theme }) => theme.phone}) {
    display: flex;
    flex-direction: column;
  }
`;