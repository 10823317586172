import React, { useState, useEffect } from "react";
import { LeftRightLayout, AnimatedText } from "#src/components";
import { motion, AnimatePresence } from "framer-motion";
import { LeftWrapper, SlideTitle, SlideWrapper, RightWrapper } from "./SlidesLayout.styles";
import { useInView } from "react-intersection-observer";
import AnimateHeight from "react-animate-height";
import { useInterval } from "#src/hooks";
import { useMedia } from "#src/hooks";

const SlidesLayout = ({ slides, disabledWhenMobile = true, ...other }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [playing, setPlaying] = useState(false);
  const { ref, inView } = useInView({ threshold: 0.9 });
  const { isTablet } = useMedia();

  const { start, stop } = useInterval(() => {
    setActiveSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
  }, slides[activeSlide].duration * 1000);

  useEffect(() => {
    if (!(isTablet && disabledWhenMobile)) {
      if (inView) {
        start();
        setPlaying(true);
      } else {
        stop();
        setPlaying(false);
      }
    }
  }, [inView, isTablet, disabledWhenMobile, start, stop]);
  useEffect(() => {
    !(isTablet && disabledWhenMobile) && start();
  }, [activeSlide, isTablet, disabledWhenMobile, start]);

  const animProps = {
    initial: { opacity: 0, y: 0 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 0 },
    transition: { duration: 0.2 },
  };
  return !(isTablet && disabledWhenMobile) ? (
    <LeftRightLayout
      ref={ref}
      reverse
      {...other}
      left={
        <LeftWrapper>
          {slides.map((slide, i) => (
            <SlideWrapper key={i}>
              <SlideTitle
                onClick={() => {
                  setActiveSlide(i);
                }}
                active={activeSlide === i}
                duration={slide.duration}
                playing={playing}
              >
                <AnimatedText delay={0.15 + i * 0.2} text={slide.title} />
              </SlideTitle>
              <AnimateHeight duration={300} height={activeSlide === i ? `auto` : 0}>
                <p>
                  <AnimatedText key={activeSlide} speed={2.5} delay={0.1} text={slide.text} />
                </p>
              </AnimateHeight>
            </SlideWrapper>
          ))}
        </LeftWrapper>
      }
      right={
        <RightWrapper>
          <AnimatePresence>
            <motion.img
              {...animProps}
              transition={{ duration: 1 }}
              key={activeSlide}
              src={slides[activeSlide].image}
              alt={slides[activeSlide].title}
            />
          </AnimatePresence>
        </RightWrapper>
      }
    />
  ) : (
    slides.map((slide, i) => (
      <LeftRightLayout key={i}
        left={
          <>
            <h3>
              <AnimatedText delay={0.15 + i * 0.2} text={slide.title} />
            </h3>
            <p>
              <AnimatedText key={activeSlide} speed={2.5} delay={0.1} text={slide.text} />
            </p>
          </>
        }
        right={<img src={slide.image} alt={slide.title} />}
      />
    ))
  );
};

export default SlidesLayout;
