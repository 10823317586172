import { useState, useEffect } from "react";

const PHONE = "480px";
const MOBILE = "768px";
const TABLET = "1180px";
const DESKTOP = "1280px";

export const useMedia = () => {
  const [matches, setMatches] = useState({
    isPhone: window.matchMedia(`(max-width: ${PHONE})`).matches,
    isMobile: window.matchMedia(`(max-width: ${MOBILE})`).matches,
    isTablet: window.matchMedia(`(max-width: ${TABLET})`).matches,
    isDesktop: window.matchMedia(`(min-width: ${DESKTOP})`).matches,
  });

  useEffect(() => {
    const updateMatches = () => {
      setMatches({
        isPhone: window.matchMedia(`(max-width: ${PHONE})`).matches,
        isMobile: window.matchMedia(`(max-width: ${MOBILE})`).matches,
        isTablet: window.matchMedia(`(max-width: ${TABLET})`).matches,
        isDesktop: window.matchMedia(`(min-width: ${DESKTOP})`).matches,
      });
    };

    window.addEventListener("resize", updateMatches);

    return () => {
      window.removeEventListener("resize", updateMatches);
    };
  }, []);

  return matches;
};