import styled from "styled-components";
import { AnimationContainer } from "#src/components";

export const Column = styled(AnimationContainer)`
  height: 100%;
  p {
    font-size: 18px;
    line-height: 23px;
    color: ${({ theme }) => theme.colors.secondary};
  }
  h4 {
    margin-bottom: 12px;
  }
`;
