import styled from "styled-components";
import { AnimationContainer } from "../AnimationContainer";
import { rgba } from "#src/Helpers";

export const Card = styled(AnimationContainer)`
  ${({ theme }) => theme.gradientStroke()};
  background: radial-gradient(
    163.67% 149.63% at -10% -60%,
    ${({ theme }) => rgba(theme.colors.primary, 0.4)} 0%,
    ${({ theme }) => rgba(theme.colors.primary, 0.01)} 100%
  );
  backdrop-filter: blur(40px);
  display: flex;
  align-items: center;
  padding: 32px;
  gap: 24px;
  min-height: 200px;
  @media (max-width: ${({ theme }) => theme.tablet}) {
  min-height: 160px;
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    padding: 32px 18px;
  }
  h4 {
    color: ${({ theme }) => theme.colors.primary};
  }
  h2 {
    font-size: 56px;
    line-height: 56px;
    color: ${({ theme }) => theme.colors.title};
    position: relative;
    sup {
      font-size: 24px;
      color: ${({ theme }) => theme.colors.primary};
      position: absolute;
      top: -12px;
    }
  }
  p {
    white-space: pre-line;
  }
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${({ valueWidth }) => (valueWidth ? `${valueWidth}px` : `96px`)};
  min-width: 90px;
  gap: 6px;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    min-width: 90px;
  }
`;
