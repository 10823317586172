import styled, { css, keyframes } from "styled-components";

export const LeftWrapper = styled.div`
  ${({ theme }) => theme.flex("column", "center", "flex-start")};
`;
export const RightWrapper = styled.div`
  position: relative;
  height: 500px;
  width: 100%;
  ${({ theme }) => theme.flex()};
  img {
    position: absolute;
    width: 100%;
  }
`;
export const SlideWrapper = styled.div`
  p {
    padding-bottom: 32px;
    max-width: 450px;
  }
`;

const anim = keyframes`
    0% {
        transform: scale(0, 1);
    }
    100% {
        transform: scale(1, 1);
    }
    `;

export const SlideTitle = styled.h3`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 32px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  &::before,
  &::after {
    ${({ theme }) => theme.pseudoFull};
    height: 2px;
    background-color: ${({ theme }) => theme.colors.dark};
    top: auto;
    bottom: -10px;
  }
  &::before {
    opacity: 0;
    transition: opacity 0.2s ${({ theme }) => theme.ease};
  }
  &::after {
    transform: scale(0, 1);
    transform-origin: left center;
    background-color: ${({ theme }) => theme.colors.primary};
  }
  ${({ active }) =>
    active &&
    css`
      &::before {
        opacity: 1;
      }
      &::after {
        animation-name:  ${({ playing }) => playing && anim};
        animation-duration: ${({ duration }) => duration}s;
        animation-timing-function: linear;
        /* animation-play-state: ${({ playing }) => playing ? 'running' : 'stopped'}; */
      }

      color: ${({ theme }) => theme.colors.title};
    `}
`;
