import styled from "styled-components";
import { AnimationContainer } from "../AnimationContainer";

export const FAQContainer = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidthSm};
  ${({ theme }) => theme.flex('column')};
  h4 {
    color: ${({ theme }) => theme.colors.title};
    padding-right: 16px;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.secondary};
    max-width: 750px;
    @media (max-width: ${({ theme }) => theme.tablet}) {
      margin-top: 10px;
    }
  }
`;

export const Question = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  transition: padding-bottom 0.4s ease-in-out;
  padding: 32px 0 16px 0;
`;

export const Answer = styled.div`
  padding: 0 32px 16px 0;
  width: 100%;
`;

export const PlusMinus = styled.span`
  height: 16px;
  width: 16px;
  flex: 0 0 16px;
  position: relative;
  color: ${({ theme }) => theme.colors.secondary};
  transition: color 0.2s ease-in-out;
  ${({ theme }) => theme.flex()};
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background-color: currentColor;
    transition: transform 0.2s ease-in-out;
    transform: rotate(${({ minus }) => (minus ? "0deg" : "180deg")});
  }
  &:after {
    transform: rotate(${({ minus }) => (minus ? "0deg" : "90deg")});
  }
`;

export const FAQWrapper = styled(AnimationContainer)`
  border-bottom: 1px solid rgba(129, 147, 174, 0.19);
  padding-bottom: 16px;
  width: 100%;
  &:hover {
    ${PlusMinus} {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
