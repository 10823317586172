import styled from "styled-components";
import FullPageHero from "../FullPageHero";
import { ArrowNav, ChildrenContainer } from "../FullPageHero/FullPageHero.styles";

export const Wrapper = styled(FullPageHero)`
  height: auto;
  min-height: auto;
  padding: 0;
  margin-top: 0;
  width: 100%;
  align-self: stretch;
  &::before,
  &::after {
    background: none;
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    align-items: flex-start;
    text-align: left;
    margin-top: 0;
  }
  ${ChildrenContainer} {
    align-items: center;
    text-align: center;
    @media (max-width: ${({ theme }) => theme.tablet}) {
      align-items: flex-start;
      text-align: left;
    }
    max-width: ${({ theme }) => theme.maxWidthSm};
    gap: 24px;
  }
  ${ArrowNav} {
    display: none;
  }
  img {
    width: 100%;
    height: auto;
  }
`;
