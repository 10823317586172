import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled.div`
  position: relative;
`;
export const TargetContainer = styled.div``;
export const MenuContainer = styled(motion.div)`
  will-change: transform;
  padding: 0 1rem 1rem 1rem;
  position: absolute;
  top: 100%;
  left: ${({ offsetleft }) => `calc(-1rem - ${offsetleft})`};
  z-index: 9997;
  width: auto;
`;
