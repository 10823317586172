import React from "react";
import {
  Container,
  CommonHero,
  SeparatorSection,
  LeftRightLayout,
  AnimatedText,
  InfoBoxGrid,
  PreFooter,
  ShareMeta,
} from "#src/components";
import { taskmanagerData as data } from "#src/data";

export default function TaskManager() {
  const { hero, sections } = data;
  return (
    <Container>
      <ShareMeta title={hero.title} description={hero.text} type="website" />
      <CommonHero {...hero} center imageProps={{ screen: 1 }} />
      <SeparatorSection>
        <LeftRightLayout
          reverse
          columns="1.1fr 0.9fr"
          left={
            <>
              <h3 className="desktop:pr-10">
                <AnimatedText text={sections[0].title} />
              </h3>
              <h4>
                <AnimatedText text={sections[0].subtitle} />
              </h4>
              <p>
                <AnimatedText block text={sections[0].text} />
              </p>
            </>
          }
          right={<img src={sections[0].image} alt={sections[0].title} />}
        />
      </SeparatorSection>
      <SeparatorSection>
        <LeftRightLayout
          // imgHeight="560px"
          columns="0.9fr 1.1fr "
          left={
            <>
              <h3 className="desktop:pr-10">
                <AnimatedText text={sections[1].title} />
              </h3>
              <h4>
                <AnimatedText text={sections[1].subtitle} />
              </h4>
              <p>
                <AnimatedText block text={sections[1].text} />
              </p>
            </>
          }
          right={<img src={sections[1].image} alt={sections[1].title} />}
        />
      </SeparatorSection>
      <SeparatorSection>
        <h3 className="center mb-10">
          <AnimatedText text={sections[2].title} />
        </h3>
        <InfoBoxGrid boxes={sections[2].features} />
      </SeparatorSection>
      <PreFooter />
    </Container>
  );
}
