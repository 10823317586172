import React from "react";
import {
  SeparatorSection,
  AnimatedText,
  AnimationContainer,
  Label,
} from "#src/components";
import { Quote, StyledImageBackground, StyledLeftRightLayout } from "./QuoteSection.styles";

const QuoteSection = ({ id, label, title, image, quote, author, reverse = "true" }) => {
  return (
    <SeparatorSection id={`${id}`}>
      {quote && title && (
        <h3 className="center mb-10" style={{width: "100%"}}>
          <AnimatedText text={title} />
        </h3>
      )}
      <StyledLeftRightLayout
      columns="0.5fr 1fr"
        reverse={reverse}
        left={
          <>
            {label && (
              <AnimationContainer>
                <Label text={label} />
              </AnimationContainer>
            )}
            {quote && (
              <Quote className="desktop:pr-10">
                <cite>
                  <AnimatedText text={quote} />
                </cite>
                <footer>
                  <AnimatedText delay={1.4} text={author} />
                </footer>
              </Quote>
            )}
          </>
        }
        right={<StyledImageBackground url={image} />}
      />
    </SeparatorSection>
  );
};

export default QuoteSection;
