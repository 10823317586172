import React from "react";
import { StickyWrapper, ImageWrapper, ImagesWrapper, UiElement } from "./Timeline.styles";
import { AnimatePresence } from "framer-motion";
import { Aurora, RotatingCard } from "#src/components";
import { transposeNumber } from "#src/Helpers";

const Illustration = ({ item, items, active }) => {
  const animProps = {
    initial: { opacity: 0, y: 0, scale: 0.95, rotateX: 10 },
    animate: { opacity: 1, y: 0, scale: 1, rotateX: 0 },
    exit: { opacity: 0, y: 0, scale: 0.95, rotateX: -10 },
    transition: { duration: 0.2 },
  };
  return (
    <StickyWrapper>
      {!!item && (
        <AnimatePresence>
          <ImageWrapper {...animProps} key={item.image} style={{ backgroundImage: `url(${item.image}` }}>
            <UiElement
              style={{
                backgroundImage: `url(${item.uiElement.image}`,
                ...item.uiElement.style,
              }}
            />
          </ImageWrapper>
        </AnimatePresence>
      )}
      {!!items && (
        <ImagesWrapper>
          <RotatingCard
            fadeBorder={0}
            dampen={30}
            topLayer={
              <>
                {items.map((item, i) => (
                  <UiElement
                    style={{
                      backgroundImage: `url(${item.uiElement.image}`,
                      opacity: transposeNumber(active[i], 1, 0.5, 0, 1),
                      ...item.uiElement.style,
                    }}
                  />
                ))}
              </>
            }
          >
            {items.map((item, i) => (
              <ImageWrapper
                key={i}
                style={{
                  backgroundImage: `url(${item.image}`,
                  opacity: transposeNumber(active[i], 1, 0.5, 0, 1),
                }}
              />
            ))}
          </RotatingCard>
        </ImagesWrapper>
      )}
    </StickyWrapper>
  );
};

export default Illustration;
