import React, { useState, useCallback, useEffect } from "react";
import { Wrapper, TimelineItemsContainer, Line, StyledCenterLayout, ImageWrapper } from "./Timeline.styles";
import { AnimatedText, AnimationContainer } from "#src/components";
import Item from "./Item";
import Illustration from "./Illustration";
import { useMedia } from "#src/hooks";

const Timeline = ({ data }) => {
  const { title, text, items } = data;
  const [active, setActive] = useState(Array(items.length).fill(1));
  const { isTablet } = useMedia();
  const setActiveCallback = useCallback((r, i) => {
    setActive(prevActive => {
      const newActive = [...prevActive]; // Create a copy of the previous active state array
      newActive[i] = r; // Update the "r" value for the corresponding item index
      return newActive;
    });
  }, [setActive]);

  useEffect(() => {
    if (typeof setActiveCallback === 'function') {
      setActiveCallback(active); // Pass the entire active state array
    }
  }, [setActiveCallback]);
  return (
    <>
      <StyledCenterLayout id="timeline">
        <h3>
          <AnimatedText text={title} />
        </h3>
        <p className="mb-6">
          <AnimatedText block text={text} />
        </p>
      </StyledCenterLayout>
      <Wrapper>
        {!isTablet && (
          <>
            <Illustration items={items} active={active} />
            <Line />
          </>
        )}
        <TimelineItemsContainer>
          {items.map((item, i) => (
            <div key={i}>
              {isTablet && (
                <AnimationContainer>
                  <Illustration item={item} />
                </AnimationContainer>
              )}
              <Item key={i} index={i} setActiveCallback={setActiveCallback} {...item} />
            </div>
          ))}
        </TimelineItemsContainer>
      </Wrapper>
    </>
  );
};

export default Timeline;
