import styled, { css, keyframes } from "styled-components";
import { Container } from "../LeftRightLayout/LeftRightLayout.styles";
import { LeftRightLayout, ActionsContainer, Logos, AnimationContainer } from "#src/components";
import { Left, Right } from "../LeftRightLayout/LeftRightLayout.styles";
import FullPageHero from "../FullPageHero";
import { Label } from "../Label";
import { VideoPlayer } from "../VideoPlayer";

export const StyledVideoPlayer = styled(VideoPlayer)``;

export const IllustrationContainer = styled(AnimationContainer)`
  height: ${({ video }) => (video ? "auto" : "60vh")};
  width: auto;
  position: absolute;
  left: 0;
  ${({ theme }) => theme.flex("row", "flex-start", "center")};
  ${({ screen, center }) =>
    screen &&
    css`
      ${({ theme }) => theme.gradientStroke("18px")};
      box-shadow: 0px 20px 100px 0px rgba(0, 0, 0, 0.4);
      overflow: hidden;
      position: absolute;
      ${!center &&
      css`
        mask-image: radial-gradient(ellipse 100% 150% at 35% 50%, black 30%, transparent 60%);
      `}
      ${center &&
      css`
        left: auto;
      `}
      @media (max-width: ${({ theme }) => theme.tablet}) {
        border-radius: 8px;
        mask-image: none;
        &:before {
          border-radius: 8px;
        }
      }
    `}
  ${({ blurFrame }) =>
    blurFrame &&
    css`
      overflow: hidden;
      padding: 38px;
      height: calc(60vh + 64px);
      > * {
        position: relative;
        z-index: 1;
      }
      ${({ theme }) => theme.gradientStroke("32px")};
      position: absolute;
      &:before {
        z-index: 1;
        opacity: 0.5;
      }
      background: linear-gradient(181deg, rgba(25, 35, 47, 0.34) 31.95%, rgba(26, 36, 48, 0) 88.47%);
      backdrop-filter: blur(20px);
      z-index: 0;
    `}
  img, ${StyledVideoPlayer} {
    height: 100%;
    width: auto;
    /* max-width: 100%; */
    ${({ screen }) =>
      screen &&
      css`
        max-width: none;
      `}
    ${({ imgFullWidth }) =>
      imgFullWidth &&
      css`
        width: 100%;
        height: auto;
      `}
  }

  @media (max-width: ${({ theme }) => theme.tablet}) {
    height: 35vh;
    width: min-content;
    position: absolute;
    img,
    ${StyledVideoPlayer} {
      width: auto;
      height: 100%;
      display: block;
    }
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    position: relative;
  }
  @media (max-width: ${({ theme }) => theme.phone}) {
    width: 100%;
    height: min-content;
    img,
    ${StyledVideoPlayer} {
      width: 100%;
      height: auto;
    }
  }
`;

export const StyledLogos = styled(Logos)`
  margin-top: 32px;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    margin-top: 0;
  }
`;

export const HeroSection = styled.section`
  position: relative;
  height: 100vh;
  height: 100dvh;
  @media (min-width: ${({ theme }) => theme.tablet}) {
    min-height: 800px;
  }
  width: 100vw;
  overflow: hidden;
  padding-top: 0;
  margin-top: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.heroBg(false)};
  @media (max-width: ${({ theme }) => theme.tablet}) {
    padding: 92px 32px 0;
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: flex;
    height: auto;
    min-height: 100vh;
    flex-direction: column;
    margin-bottom: 0;
    max-height: none;
    padding: 92px 16px 0;
  }
  @media (max-width: ${({ theme }) => theme.phone}) {
    overflow: visible;
  }
  h1 {
    width: 100%;
    line-height: 1;
    color: ${({ theme }) => theme.colors.title};
    text-align: left;
    text-transform: none;
    @media (max-width: ${({ theme }) => theme.tablet}) {
      padding-top: 0;
      font-size: 36px;
      line-height: 36px;
      max-width: 344px;
    }
  }
  p {
    max-width: 450px;
    color: ${({ theme }) => theme.colors.secondary};
    @media (max-width: ${({ theme }) => theme.tablet}) {
      max-width: none;
      /* padding-right: 30vw; */
    }
    @media (max-width: ${({ theme }) => theme.phone}) {
      padding-right: 10vw;
    }
  }
  ${StyledLogos} {
  }
`;

export const HeroLayout = styled(LeftRightLayout)`
  flex: 1;
  ${ActionsContainer} {
    margin-bottom: 2rem;
  }
  ${Container} {
    max-width: ${({ theme }) => theme.maxWidth};
    @media (max-width: ${({ theme }) => theme.tablet}) {
      flex-direction: column-reverse;
      ${Right} {
        position: relative;
        z-index: 0 !important;
      }
      ${Left} {
        position: relative;
        z-index: 1;
      }
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
      margin-top: 20px;
    }
  }
  ${({ smallTitle }) =>
    smallTitle &&
    css`
      h1 {
        font-size: 42px;
        line-height: 48px;
      }
    `}
`;

export const StyledLabel = styled(Label)`
  margin-bottom: 24px;
`;

export const IllustrationWrapper = styled.div`
  width: 100vw;
  padding-top: 64px;
  ${({ theme }) => theme.flex()};
  position: relative;
  height: 25vh;
`;
const opacity = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const StyledFullPageHero = styled(FullPageHero)`
  overflow: hidden;
  height: 100vh;
  height: 100dvh;
  @media (min-width: ${({ theme }) => theme.mobile}) {
    min-height: 800px;
  }
  ${IllustrationContainer} {
    position: absolute;
    top: 64px;
    height: auto;
    max-width: ${({ theme }) => theme.maxWidth};
    width: calc(80vw - 64px);
    background: linear-gradient(181deg, rgba(11 8 17 / 0.2) 31.95%, rgba(11 8 26 / 0.4) 88.47%);
    img {
      opacity: 0;
      animation: 1s 3s forwards ${opacity};
      height: auto;
      width: 100%;
    }
  }
`;
const strokeLength = 1780;
export const StyledSvg = styled.svg`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  path,
  rect {
    stroke-dasharray: ${strokeLength};
    stroke-dashoffset: ${strokeLength};
    animation: 4s forwards dash;
  }
  @keyframes dash {
    0% {
      stroke-dashoffset: -${strokeLength};
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
`;

const opacity2 = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
`;

export const EdgeShineSvg = styled.svg`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  use {
    opacity: 0.6;
  }
  animation: 0.4s 2s forwards ${opacity2};
`;

export const ArrowNav = styled.div`
  cursor: pointer;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  gap: 10px;
  opacity: 0.6;
  @media (max-width: ${({ theme }) => theme.phone}) {
    align-items: center;
    align-self: flex-start;
    flex-direction: row-reverse;
    position: relative;
    padding-top: 2rem;
  }
  span {
    color: ${({ theme }) => theme.colors.title};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    text-transform: none;
  }
  svg {
    color: ${({ theme }) => theme.colors.title};
    transition: translate 0.3s ${({ theme }) => theme.ease};
    scale: 0.8;
  }
  &:hover {
    span {
      opacity: 1;
    }
    svg {
      translate: 0 10px;
    }
  }
`;
