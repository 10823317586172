import { useEffect, useContext } from "react";
import { PUBLISHEDPOSTSURL, DEVPOSTSURL, AUTHORSURL, DEVAUTHORSURL, ENV } from "../common/constants";
import { sortByDate } from "../Helpers";
import { ValueContext, DispatchContext } from "../context/Context";
import { useNavigate } from "react-router-dom";

function getArticlesWithAuthorData(articles, authors) {
  const authorsById = {};
  authors.forEach((author) => {
    authorsById[author.id] = author;
  });
  const articlesWithAuthorData = articles.map((article) => {
    const authorId = article.blogAuthorId;
    const author = authorsById[authorId];
    if (author) {
      return { ...article, authorData: author };
    }
    return article;
  });

  return articlesWithAuthorData;
}

export function useLoadNewsData() {
  const dispatch = useContext(DispatchContext);
  const { newsData } = useContext(ValueContext);
  const navigate = useNavigate();

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const articlesResponse = await fetch(ENV === "development" ? DEVPOSTSURL : PUBLISHEDPOSTSURL);
        const authorsResponse = await fetch(ENV === "development" ? DEVAUTHORSURL : AUTHORSURL);

        if (!articlesResponse.ok || !authorsResponse.ok) {
          throw new Error("Failed to fetch data");
        }

        const articles = await articlesResponse.json();
        const authors = await authorsResponse.json();
        const articlesWithAuthorData = getArticlesWithAuthorData(articles?.results, authors?.results);

        if (articles?.total === 0) {
          navigate("/error?e=nonews");
        } else {
          dispatch({ type: "setNewsData", newsData: sortByDate(articlesWithAuthorData) });
        }
      } catch (error) {
        console.error("Failed to load news data", error);
        if (!newsData) {
          navigate("/error?e=nonews");
        }
      }
    };

    if (!newsData) {
      dataFetch();
    }
  }, [dispatch, newsData, navigate]);

  return newsData;
}
