import styled from "styled-components";
import { AnimationContainer } from "../AnimationContainer";

export const LogosTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15.1385px;
  line-height: 28px;
  color: rgba(129, 147, 174, 0.6);
`;

export const LogosWrapper = styled.div`
  display: flex;
  gap: 56px;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    flex-wrap: wrap;
    gap: 24px;
  }
`;

export const LogosRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media (max-width: ${({ theme }) => theme.tablet}) {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  img {
    height: ${({ height }) => (height ? `${height}px` : "20px")};
    width: auto;
  }
`;

export const LogosContainer = styled(AnimationContainer)`
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.phone}) {
    align-items: flex-start;
  }
  gap: 20px;
  ${LogosTitle} {
    text-align: ${({ $titleAlign }) => ($titleAlign ? $titleAlign : "inherit")};
  }
  ${LogosRow} {
    gap: ${({ height, gap }) => (gap ? `${gap}px` : `${height / 2}px`)};
  }
`;
