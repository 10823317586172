import styled from "styled-components";
import { FullPageHero, AnimationContainer } from "..";
import { ChildrenContainer } from "../FullPageHero/FullPageHero.styles";

export const LinesSection = styled(FullPageHero)`
  height: auto;
  min-height: auto;
  ${ChildrenContainer} {
    gap: 56px;
    @media (max-width: ${({ theme }) => theme.tablet}) {
      gap: 32px;
    }
  }
  h2 {
    white-space: ${({ preWrapped }) => (preWrapped ? "pre-wrap" : "normal")};
    max-width: 900px;
  }
  p {
    max-width: 900px;
    padding: 0;
    margin: 0;
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2,
    p {
      text-align: center;
    }
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    ${ChildrenContainer} {
      align-items: center;
    }
  }
`;

export const TextContainer = styled(AnimationContainer)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const LineContainer = styled(AnimationContainer)``;
