import React from "react";
import { Wrapper, Grid } from "./GridLayout.styles"

const GridLayout = ({children, columns = 3, gap = "32px", ...other}) => {
  return (
    <Wrapper {...other}>
      <Grid gap={gap} columns={columns}>
        {children}
      </Grid>
    </Wrapper>
  );
};

export default React.memo(GridLayout);
