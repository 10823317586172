import React from "react";
import { InlineWidget } from "react-calendly";
import { useMedia } from "#src/hooks";
import { Wrapper } from "./CalendlyModal.styles";

const CalendlyModal = ({ url, title, ...other }) => {
  const { isTablet } = useMedia();
  const widgetStyles = {
    height: isTablet ? "1200px" : "700px",
    // width: isTablet ? "100%" : "1000px",
  };
  const pageSettings = {
    backgroundColor: "1A2430",
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: "6774F4",
    textColor: "ECEFF1",
  };
  return (
    <Wrapper>
      {!!title && <h3>{title}</h3>}
      <InlineWidget {...other} styles={widgetStyles} pageSettings={pageSettings} url={url} />
    </Wrapper>
  );
};

export default CalendlyModal;
