import styled, { css } from "styled-components";
import FullPageHero from "../FullPageHero";
import { VideoPlayer } from "../VideoPlayer";
import { ArrowNav, ChildrenContainer } from "../FullPageHero/FullPageHero.styles";

export const Wrapper = styled(FullPageHero)`
  height: auto;
  min-height: auto;
  padding: 144px 32px;
  flex: 1;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 48px 16px;
  }
  margin-top: 0;
  background-size: cover;
  background-position: center;
  ${({ video }) =>
    video
      ? css`
          background: linear-gradient(181deg, rgba(5 18 23 / 0.8) 31.95%, rgba(7 38 75 / 0.6) 88.47%);
          backdrop-filter: blur(8px);
        `
      : css`
          background-image: url(/images/elements/septsectbg.webp);
        `};

  &:after {
    opacity: 0.35;
  }
  &:before {
    opacity: 0.35;
  }
  h3,
  p {
    text-align: center;
    margin: 0;
    padding: 0;
    @media (max-width: ${({ theme }) => theme.phone}) {
      text-align: left;
    }
  }
  h3 {
    max-width: 650px;
  }
  ${ChildrenContainer} {
    align-items: center;
    @media (max-width: ${({ theme }) => theme.phone}) {
      align-items: stretch;
    }
    max-width: 700px;
    gap: 24px;
  }
  ${ArrowNav} {
    display: none;
  }
`;
export const WrapperWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: stretch;
`;
export const StyledVideoPlayer = styled(VideoPlayer)`
  position: absolute;
  width: 100vw !important;
  height: 100% !important;
  z-index: 0;
`;
