import styled from "styled-components";

export const SectionWrapper = styled.section`
  ${({ theme }) => theme.flex("column", "center", "center")};
  @media (max-width: ${({ theme }) => theme.tablet}) {
    align-items: stretch;
  }
  position: relative;
  gap: 120px;
  padding: 120px 32px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    padding: 42px 16px;
    gap: 96px;
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    gap: 72px;
    p {
      text-align: left;
    }
  }
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => theme.flex("column", "center", "center")};
  gap: 56px;
  max-width: ${({ theme }) => theme.maxWidth};
  width: 100%;
`;

export const SeparatorSectionWrapper = styled(SectionWrapper)`
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding-top: 32px;
  }
  background-repeat: no-repeat;
  overflow: clip;
  justify-content: flex-start;
`;
