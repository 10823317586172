import styled from "styled-components";
import { AnimationContainer } from "../AnimationContainer";

export const CTAWrapper = styled(AnimationContainer)`
  ${({ theme }) => theme.flex("row", "space-between", "center")};
  @media (max-width: ${({ theme }) => theme.tablet}) {
    ${({ theme }) => theme.flex("column", "center", "stretch")};
    text-align: center;
  }
  background: rgba(26, 36, 48, 0.35);
  backdrop-filter: blur(20px);
  max-width: ${({ theme }) => theme.maxWidthSm};
  width: 100%;
  gap: 24px;
  border-radius: 24px;
  padding: 24px 36px;
`;
