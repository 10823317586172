import React, { useState, useCallback, useEffect } from "react";
import { Container, TargetContainer, MenuContainer } from "./DropdownMenu.styles";
import { AnimatePresence } from "framer-motion";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useLocation } from "react-router-dom";

const DropdownMenu = ({ target, menu, onChange = () => {}, disableAnimation = false, offsetLeft = '0rem' }) => {
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState(false);
  const { pathname } = useLocation();

  const ref = useDetectClickOutside({
    onTriggered: () => {
      closeHandler();
      setClicked(false);
    },
  });

  const openHandler = useCallback(() => {
    setOpen(true);
    onChange(true);
  }, [onChange]);

  const closeHandler = useCallback(() => {
    setOpen(false);
    onChange(false);
  }, [onChange]);

  useEffect(() => {
    setOpen(false);
    // onChange(false);
  }, [pathname]);

  const animProps = disableAnimation
    ? {}
    : {
        initial: { opacity: 0, y: 0 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0 },
        transition: { duration: 0.2 },
      };

  return (
    <Container ref={ref} onMouseLeave={() => !clicked && closeHandler()}>
      <TargetContainer
        onClick={() => {
          openHandler();
          setClicked(true);
        }}
        onMouseEnter={() => openHandler()}
        open={open}
      >
        {target}
      </TargetContainer>
      <AnimatePresence>{open && <MenuContainer offsetleft={offsetLeft} {...animProps}>{menu}</MenuContainer>}</AnimatePresence>
    </Container>
  );
};

export default React.memo(DropdownMenu);
