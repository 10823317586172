import { useEffect, useRef } from "react";

export function useInterval(callback, delay) {
  const savedCallback = useRef();
  const intervalIdRef = useRef(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const start = () => {
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = setInterval(savedCallback.current, delay);
  };

  const stop = () => {
    clearInterval(intervalIdRef.current);
  };

  useEffect(() => {
    return () => stop();
  }, []);

  return { start, stop };
}
